import React, { useEffect, useState } from 'react';
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';
import { Container, Row, Col, Table, Figure, Form, Button, Modal, InputGroup, Spinner, Tooltip, OverlayTrigger, Card } from 'react-bootstrap';
import axios from 'axios';
import Cookies from 'universal-cookie';
import { Link, useLocation } from 'react-router-dom';
import * as Icon from 'react-bootstrap-icons';
import '../sources/styles/stylesCarrito.css';
import moment from 'moment';

import Swal from 'sweetalert2';

function Carrito(props) {

    const cookies = new Cookies();
    const user = cookies.get('user');
    const [arrayProductos, setArrayProductos] = useState([]);
    const [metodoPago, setMetodoPago] = useState([]);
    const [medioEntrega, setMedioEntrega] = useState([]);
    const [arraySucursales, setArraySucursales] = useState([]);
    const [arrayPuntosEnvio, setArrayPuntosEnvio] = useState([]);
    const [singleSucursal, setSingleSucursal] = useState('');
    const [singlePuntoEnvio, setSinglePuntoEnvio] = useState('');
    const [singleMetodoPago, setSingleMetodoPago] = useState('');
    const [singleMedioEntrega, setSingleMedioEntrega] = useState('');
    const [subtotal, setSubtotal] = useState(0);
    const [impuesto, setImpuesto] = useState(0);
    const [transporte, setTransporte] = useState(0);
    const [fecha_recoge, setFecha_recoge] = useState(moment().format('YYYY-MM-DD'));
    const [mensajeValidacionArchivo, setMensajeValidacionArchivo] = useState('');
    const [objPedidoSoportePago, setObjPedidoSoportePago] = useState(undefined);
    const [cantidad, setCantidad] = useState('');
    const [objModificarCantidad, setObjModificarCantidad] = useState([]);
    const [imagenBanner, setImagenBanner] = useState([]);
    const [idCliente, setIdCliente] = useState('');
    const [departamento, setDepartamento] = useState('');
    const [ciudad, setCiudad] = useState('');
    const [direccion, setDireccion] = useState('');
    const [observacion, setObservacion] = useState('');
    const [objCodDatosEntrega, setObjCodDatosEntrega] = useState({
        id_departamento: '',
        id_ciudad: '',
        nombre_ciudad: '',
        nombre_departamento: ''
    });

    const [permisoCotizacion, setPermisoCotizacion] = useState('none')
    const [show, setShow] = useState(false);
    const [showModificarCantidad, setShowModificarCantidad] = useState(false);
    const [showModalMedioPago, setShowMedioPago] = useState(false);
    const [showModalMontarPedido, setShowMontarPedido] = useState(false);
    const [showModalCantidad, setShowModalCantidad] = useState(false);
    const [showModalEspera, setShowModalEspera] = useState(false);
    const [tipoCliente, setTipoCliente] = useState('')
    const [permisoCliente, setPermisoCliente] = useState('none')
    const [loading, setLoading] = useState(false);
    const [texto, setTexto] = useState('')
    const [arrayDatos, setArrayDatos] = useState([])
    const [datosBusqueda, setDatosBusqueda] = useState('none')
    const [visualizacionMontadorPedido, setVisualizacionMontadorPedido] = useState('none');
    const [configCupon, setConfigCupon] = useState(false);

    const [cupon, setCupon] = useState({
        codigoDescuento: '',
        descuentoAplicado: false,
        descuentoCupon: 0,
        mensajeDescuento: ''
    });

    let arrayItemsPedidos = [];

    const imagenPublicidad = process.env.REACT_APP_SERVER_IMAGE + 'estaticas/';

    let [objProductos, setObjProductos] = useState([]);

    const [itemPedido, setItemPedido] = useState('0');
    const [idPedido, setIdPedido] = useState('0');

    const formatoPesosColombianos = new Intl.NumberFormat('es-CO', {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 0
    });

    const formatoPorcentaje = (num) => {
        return Number(num / 100).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2 });
    }

    const aplicarDescuento = async () => {
        if (cupon.codigoDescuento !== '') {
            queryFetch();
        }
    };

    const queryFetch = async () => {
        const cookies = new Cookies();
        const accessToken = cookies.get('accesToken');
        const user = cookies.get('user');
        setIdCliente(user.id_cliente);
        setLoading(true);
        try {
            let cuponAux = {
                codigoDescuento: '',
                descuentoAplicado: false,
                descuentoCupon: 0,
                mensajeDescuento: ''
            }

            if (cupon.codigoDescuento !== '') {
                const urlCupon = process.env.REACT_APP_SERVER + '/api/v1/discounts/cupones/validar';
                const resultCupon = await axios.post(urlCupon, { cupon },
                    {
                        headers: {
                            'Authorization': `Bearer ${accessToken}`
                        }
                    });
                const datosCupon = resultCupon.data;
                if (datosCupon.result) {
                    cuponAux = {
                        id_cupon: datosCupon.cupon[0].id_cupon,
                        descuentoCupon: datosCupon.cupon[0].valor_cupon,
                        descuentoAplicado: true,
                        mensajeDescuento: '✅Validacion Cupón Exitosa',
                        aplicabilidad: datosCupon.cupon[0].aplicabilidad,
                        tipoCupon: datosCupon.cupon[0].tipo_cupon
                    }
                } else {
                    setCupon({
                        codigoDescuento: '',
                        descuentoAplicado: false,
                        descuentoCupon: 0,
                        mensajeDescuento: '❌Código inválido'
                    });
                    Swal.fire({
                        icon: 'error',
                        title: '¡Ups!',
                        text: 'Parece que el código de descuento no es válido. Por favor, verifica el código e inténtalo de nuevo.',
                        confirmButtonText: 'Entiendo'
                    });
                }
            }

            const bannerIncicio = async () => {
                const urlBanner = process.env.REACT_APP_SERVER + '/api/v1/products/banner';
                const dataBanner = await axios.post(urlBanner, { pagina: 'carrito' }, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    }
                });
                const banner = dataBanner.data;
                setImagenBanner(banner);
            }

            setTimeout(() => {
                bannerIncicio();
            }, 1000);

            const urlCarrito = process.env.REACT_APP_SERVER + '/api/v1/delete_prodctuCart';
            await axios.get(urlCarrito, {
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                }
            });
            const urlCarritoHierro = process.env.REACT_APP_SERVER + '/api/v1/delete_prodctuCartHierro';
            await axios.get(urlCarritoHierro, {
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                }
            });

            const urlConfigCupon = process.env.REACT_APP_SERVER + '/api/v1/discounts/cupones/config';
            const resultConfigCupon = await axios.get(urlConfigCupon, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });
            if (resultConfigCupon.data.result) {
                setConfigCupon(true);
            }

            const url = process.env.REACT_APP_SERVER + '/api/v1/shoppingCart/list';
            const response = await axios.get(url, {
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                }
            });
            const data = response.data[0];

            let arrayAux = [];
            let subtotalFinal = 0;
            let impuestoFinal = 0;

            let validacionDescuentoCupon = true;
            for (let index = 0; index < data.length; index++) {
                data[index].image = process.env.REACT_APP_SERVER_IMAGE + 'items/' + data[index].item + '_0_240x240.webp';
                if (data[index].sobre_pedido === 'SI') {
                    const urlUjueta = 'https://api-clientes.ujueta.com/products/sku/' + data[index].item;
                    await axios.get(urlUjueta, {
                        headers: {
                            'accept': '*/*',
                            'api_key': '544a2a20-44ef-46d2-a434-6b313d7362f0',
                        },
                    }).then((resultUjueta) => {
                        if (resultUjueta.status === 200) {
                            const dataUjueta = resultUjueta.data.data[0];
                            data[index].item = data[index].id;
                            data[index].descripcion_item = dataUjueta.label.toUpperCase().substring(0, 100);
                            data[index].alto = dataUjueta.attributes.medida_caja_alto_cm ? dataUjueta.attributes.medida_caja_alto_cm : '10.00';
                            data[index].ancho = dataUjueta.attributes.medida_caja_ancho_cm ? dataUjueta.attributes.medida_caja_ancho_cm : '10.00';
                            data[index].largo = dataUjueta.attributes.medida_caja_largo_cm ? dataUjueta.attributes.medida_caja_largo_cm : '10.00';
                            data[index].peso = dataUjueta.attributes.peso_caja_kg ? dataUjueta.attributes.peso_caja_kg : '2.00';
                            data[index].image = dataUjueta.thumbnail.thumbnail;
                            data[index].referencia = resultUjueta.data.sku;
                        }
                    }).catch((e) => {
                        console.log(e);
                    });
                }
                let total = parseInt(data[index].precio) * parseInt(data[index].cantidad) * (1 - parseFloat(data[index].descuento) / 100);
                let descuentoOriginal = data[index].descuento_original;
                let descuento = data[index].descuento;
                if (resultConfigCupon.data.result) {
                    if (cuponAux.descuentoAplicado) {
                        if (cuponAux.tipoCupon === 'Sublinea') {
                            if (cuponAux.aplicabilidad === data[index].sublinea) {
                                validacionDescuentoCupon = false;
                                total = total * (1 - parseFloat(cuponAux.descuentoCupon) / 100);
                                descuentoOriginal = cuponAux.descuentoCupon;
                                descuento = cuponAux.descuentoCupon;
                                setCupon({
                                    ...cupon,
                                    ...cuponAux
                                });
                            }
                        } else if (cuponAux.tipoCupon === 'Items') {
                            const aplicabilidadArray = cuponAux.aplicabilidad.split(',');
                            aplicabilidadArray.forEach((aplicabilidadItem) => {
                                if (parseInt(aplicabilidadItem.trim()) === data[index].item) {
                                    validacionDescuentoCupon = false;
                                    total = total * (1 - parseFloat(cuponAux.descuentoCupon) / 100);
                                    descuentoOriginal = cuponAux.descuentoCupon;
                                    descuento = cuponAux.descuentoCupon;
                                    setCupon({
                                        ...cupon,
                                        ...cuponAux
                                    });
                                }
                            });
                        }
                    }
                }
                let subtotal = parseInt(total) / (1 + parseFloat(data[index].iva / 100));
                let impuesto = subtotal * parseFloat(data[index].iva) / 100;
                subtotalFinal += Math.round(subtotal);
                impuestoFinal += Math.round(impuesto);
                let objAux = {
                    id: data[index].id,
                    item: data[index].item,
                    descripcion_item: data[index].descripcion_item,
                    cantidad: data[index].cantidad,
                    alto: data[index].alto,
                    ancho: data[index].ancho,
                    largo: data[index].largo,
                    peso: data[index].peso,
                    precio: data[index].precio,
                    descuento_original: descuentoOriginal,
                    descuento: descuento,
                    image: data[index].image,
                    referencia: data[index].referencia,
                    um: data[index].um,
                    iva: data[index].iva,
                    valor_final: data[index].valor_final,
                    subtotal: Math.round(subtotal),
                    impuesto: Math.round(impuesto),
                    total: total,
                    sublinea: data[index].sublinea,
                    sobre_pedido: data[index].sobre_pedido
                }
                arrayAux.push(objAux);
            }
            if (cuponAux.descuentoAplicado && validacionDescuentoCupon) {
                let mensajeCupon = '❌Error!.Código aplicable solo a ' + cuponAux.aplicabilidad.substring(5);
                if (cuponAux.tipoCupon === 'Items') {
                    mensajeCupon = '❌Error!.Código No aplicable a los productos en Carrito';
                }
                setCupon({
                    ...cupon,
                    descuentoAplicado: false,
                    descuentoCupon: 0,
                    mensajeDescuento: mensajeCupon
                });
                Swal.fire({
                    icon: 'error',
                    title: '¡Ups!',
                    text: mensajeCupon,
                    confirmButtonText: 'Entiendo'
                });
            }
            if (validacionDescuentoCupon === false) {
                Swal.fire({
                    icon: 'success',
                    title: '¡Éxito!',
                    text: 'El cupón se ha aplicado correctamente.',
                    confirmButtonText: 'Genial'
                });
            }
            setArrayProductos(arrayAux);
            setImpuesto(impuestoFinal);
            setSubtotal(subtotalFinal);
            querySucursales((subtotal + impuesto), arrayAux)
            cookies.set('accesToken', response.data[1], { path: '/' })
        } catch (error) {
            console.log('Ocurrió error en la consulta' + error);
        }
        setLoading(false);
    }

    const handlePDFSoportePago = e => {
        let arrayAux = [];
        for (let index = 0; index < e.target.files.length; index++) {
            arrayAux.push(e.target.files[index]);
        }

        subirPdfSoportePago(arrayAux);
    }

    const changeCantidad = async (id, cantidad) => {
        const cookies = new Cookies();
        const accessToken = cookies.get('accesToken');
        setCantidad(cantidad + 1)
        try {
            let body = { id, cantidad };
            const url = process.env.REACT_APP_SERVER + '/api/v1/shoppingCart/edit';
            const response = await axios.post(url, body, {
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                }
            });
            const data = response.data;

            if (data.result) {
                queryFetch();
            }
        } catch (error) {
            console.log('Ocurrió error en la consulta' + error);
        }
    }

    const handleSumar = async (id, cantidad2, item, descuento) => {
        const cookies = new Cookies();
        const accessToken = cookies.get('accesToken');
        setCantidad(parseInt(cantidad) + 1);

        try {
            const urlDescuentosVolumen = process.env.REACT_APP_SERVER + '/api/v1/discountsVolume';
            const bodyDescuentosVolumen = { item, cantidad: parseFloat(cantidad2) + 1, descuento }
            const responseDescuentosVolumen = await axios.post(urlDescuentosVolumen, bodyDescuentosVolumen, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });

            let body = { id, cantidad: parseInt(cantidad2) + 1, descuento: responseDescuentosVolumen.data.descuentoFinal };
            const url = process.env.REACT_APP_SERVER + '/api/v1/shoppingCart/edit';
            const response = await axios.post(url, body, {
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                }
            });
            const data = response.data;

            if (data.result) {
                queryFetch();
            }
        } catch (error) {
            console.log('Ocurrió error en la consulta' + error);
        }
    }

    const handleRestar = async (id, cantidad, item, descuento, index) => {
        const cookies = new Cookies();
        const accessToken = cookies.get('accesToken');

        if (parseInt(cantidad) <= 1) {
            mostrarAlert('error', 'Advertencia', 'La cantidad minima permitida es uno');
        } else {
            setCantidad(parseInt(cantidad) - 1);

            try {

                const urlDescuentosVolumen = process.env.REACT_APP_SERVER + '/api/v1/discountsVolume';
                const bodyDescuentosVolumen = { item, cantidad: parseInt(cantidad) - 1, descuento }
                const responseDescuentosVolumen = await axios.post(urlDescuentosVolumen, bodyDescuentosVolumen, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    }
                });

                let body = { id, cantidad: parseInt(cantidad) - 1, descuento: responseDescuentosVolumen.data.descuentoFinal };
                const url = process.env.REACT_APP_SERVER + '/api/v1/shoppingCart/edit';
                const response = await axios.post(url, body, {
                    headers: {
                        "Authorization": `Bearer ${accessToken}`
                    }
                });
                const data = response.data;



                if (data.result) {
                    queryFetch();
                }
            } catch (error) {
                console.log('Ocurrió error en la consulta' + error);
            }
        }
    }

    const handleShowModalModificarCantidad = (id, cantidad, item, descuento) => {
        setShowModificarCantidad(true)
        setCantidad(cantidad);
        let objAux = {
            id,
            item,
            descuento,
            cantidad
        }
        setObjModificarCantidad(objAux)
    }
    const queryListPrice = async () => {
        const cookies = new Cookies();
        let token = cookies.get('accesToken');

        const urlCliente = process.env.REACT_APP_SERVER + '/api/v1/validarListaPrecio';
        const respCliente = await axios.get(urlCliente, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });

        setTipoCliente(respCliente.data.tipo_cliente_pagina)
        if (respCliente.data.lista_de_precios === '001' || respCliente.data.lista_de_precios === '014') {
            setPermisoCotizacion('none')
        } else {
            setPermisoCotizacion('block')

        }
        if (respCliente.data.tipo_cliente_pagina === 'B2B') {
            setPermisoCliente('flex');
            setVisualizacionMontadorPedido('block')
            document.getElementById('btnPedido').disabled = true;
        }
    }

    const queryMetodoPago = async () => {
        const cookies = new Cookies();
        const accessToken = cookies.get('accesToken');
        try {
            const url = process.env.REACT_APP_SERVER + '/api/v1/carrito/metodoPago';
            const { data } = await axios.get(url, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });
            setSingleMetodoPago(data[0].metodo)
            setMetodoPago(data);
        } catch (err) {
            console.log('Ocurrio error en la consulta de metodo de pago:' + err);
        }
    }

    const queryMedioEntrega = async () => {
        const cookies = new Cookies();
        const accessToken = cookies.get('accesToken');
        try {
            const url = process.env.REACT_APP_SERVER + '/api/v1/carrito/medioEntrega';
            const { data } = await axios.get(url, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });

            setSingleMedioEntrega(data[0].medio)
            setMedioEntrega(data);
        } catch (err) {
            console.log('Ocurrió error en la consulta de medios de entrega: ' + err);
        }
    }

    const querySucursales = async (valorDeclaradoApi, itemsApi) => {
        const cookies = new Cookies();
        const accessToken = cookies.get('accesToken');
        try {
            const url = process.env.REACT_APP_SERVER + '/api/v1/carrito/sucursales';
            const { data } = await axios.get(url, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });

            if (data.length === 0) {
                data.push({ codigo: '001', descripcion: '001' });
            }
            setSingleSucursal(data[0].codigo)
            setArraySucursales(data);
            queryPuntosEnvio(data[0].codigo, valorDeclaradoApi, itemsApi);
        } catch (err) {
            console.log('Ocurrió error en la consulta de sucursales: ' + err);
        }
    }

    const queryPuntosEnvio = async (idSucursal, valorDeclaradoApi, itemsApi) => {
        if (idSucursal !== "") {
            const cookies = new Cookies();
            const accessToken = cookies.get('accesToken');
            try {
                const url = process.env.REACT_APP_SERVER + '/api/v1/carrito/puntosEnvio';
                const { data } = await axios.post(url, { idSucursal: idSucursal }, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    }
                });

                if (data.length === 0) {
                    data.push({ codigo: '000', descripcion: '000', id_departamento: '', departamento: '', id_ciudad: '', ciudad: '', direccion: '' });
                }

                setSinglePuntoEnvio(data[0].codigo)
                setArrayPuntosEnvio(data);
                handleDatosEntrega(data[0], valorDeclaradoApi, itemsApi);

            } catch (err) {
                console.log('Ocurrió error en la consulta de puntos de envio: ' + err);
            }
        }
    }

    const queryPrecioTransporte = async (items, valorDeclarado, ciudad2, departamento2, medioEntrega) => {
        const cookies = new Cookies();
        const accessToken = cookies.get('accesToken');

        try {
            if (user.tipo_usuario !== 'temporal') {
                if (medioEntrega === 'Entrega') {
                    let concatCiudad = `${departamento2}${ciudad2}`;
                    if ((concatCiudad === '76111') || (concatCiudad === '76520') || (concatCiudad === '76834')) {
                        document.getElementById('btnPedido').disabled = true;//Inactiva
                        const url = process.env.REACT_APP_SERVER + '/api/v1/envio/despacho';
                        const body = { items, departamento: departamento2, ciudad_destino: ciudad2 }
                        const { data } = await axios.post(url, body, {
                            headers: {
                                'Authorization': `Bearer ${accessToken}`
                            }
                        });
                        setTransporte(data[0]);
                        document.getElementById('btnPedido').disabled = false;//Activa
                    } else {
                        document.getElementById('btnPedido').disabled = true;//Inactiva
                        const url = process.env.REACT_APP_SERVER + '/api/v1/cotizarEnvio';
                        const body = { items: items, valorDeclarado, ciudad: ciudad2, departamento: departamento2 };

                        const { data } = await axios.post(url, body, {
                            headers: {
                                'Authorization': `Bearer ${accessToken}`
                            }
                        });

                        setTransporte(data[0]);
                        document.getElementById('btnPedido').disabled = false;//Activa
                    }
                } else if (medioEntrega === 'Despacho') {

                    const url = process.env.REACT_APP_SERVER + '/api/v1/envio/despacho';
                    const body = { items, departamento: departamento2, ciudad_destino: ciudad2 }
                    const { data } = await axios.post(url, body, {
                        headers: {
                            'Authorization': `Bearer ${accessToken}`
                        }
                    });
                    setTransporte(data[0]);
                } else {
                    setTransporte(0);
                }
            }
        } catch (err) {
            console.log('Ocurrió error en la consulta de precio transporte:' + err);
        }
    }

    const queryPedido = async () => {
        document.getElementById('btnPedido').disabled = true;
        validationRoute();
        const cookies = new Cookies();
        const accessToken = cookies.get('accesToken');

        let validacionCampos = true;
        let mensajeValidacion = "";
        let direcccionFinal = direccion;
        if (user.tipo_documento === null) {
            user.tipo_documento = 'CC';
        }
        if (user.tipo_usuario === 'temporal') {
            objCodDatosEntrega.id_departamento = objFormulario.departamento;
            objCodDatosEntrega.id_ciudad = objFormulario.ciudad;
            direcccionFinal = objFormulario.direccion;
            setDepartamento(objFormulario.departamento);
            setCiudad(objFormulario.ciudad);
            setDireccion(objFormulario.direccion);
            user.telefono = objFormulario.telefono;
            user.tipo_documento = objFormulario.tipo_documento;
            user.numero_identificacion = objFormulario.numero_identificacion;
            user.nombre = objFormulario.nombre;
            user.email = objFormulario.email;
            user.apellido_1 = objFormulario.apellido_1;
        }

        if (singleMedioEntrega === "Recoge en tienda" ||
            singleMedioEntrega === "Recoge" || singleMedioEntrega === "Recoge en bodega") {

            if (fecha_recoge === '' || fecha_recoge === undefined) {
                validacionCampos = false;
                mensajeValidacion = "La fecha de entrega es obligatoria";
            }
        } else {
            setFecha_recoge('');
            if (user.tipo_usuario !== 'temporal') {
                if (departamento === '' || departamento === undefined) {
                    validacionCampos = false;
                    mensajeValidacion = "El departamento es obligatorio";
                } else if (ciudad === '' || ciudad === undefined) {
                    validacionCampos = false;
                    mensajeValidacion = "La ciudad es obligatoria";
                } else if (direccion === '' || direccion === undefined) {
                    validacionCampos = false;
                    mensajeValidacion = "La dirección es obligatoria";
                }
            }
        }

        if (arrayProductos.length === 0) {
            validacionCampos = false;
            mensajeValidacion = "Debe de agregar al menos un item al carrito";
        }
        if (validacionCampos) {
            if (tipoCliente === 'CATALOGO') {
                try {
                    const url = process.env.REACT_APP_SERVER + '/api/v1/pedidos_catalogo/register';
                    const body = {
                        medio_de_pago: singleMetodoPago,
                        medio_de_entrega: singleMedioEntrega,
                        items: arrayProductos,
                        soportePago: objPedidoSoportePago,
                        transporte,
                        sucursal: singleSucursal,
                        punto_envio: singlePuntoEnvio,
                        valor_final: subtotal + impuesto + Math.round(transporte),
                        fecha_recoge: fecha_recoge === '' ? moment().format('YYYY-MM-DD') : fecha_recoge,
                        departamento: objCodDatosEntrega.id_departamento,
                        ciudad: objCodDatosEntrega.id_ciudad,
                        nombre_departamento: objCodDatosEntrega.nombre_departamento,
                        nombre_ciudad: objCodDatosEntrega.nombre_ciudad,
                        direccion,
                        merchantId: process.env.REACT_APP_MERCHANT_ID,
                        currency: process.env.REACT_APP_CURRENCY,
                        observaciones: observacion
                    }
                    const { data } = await axios.post(url, body, {
                        headers: {
                            'Authorization': `Bearer ${accessToken}`
                        }
                    });
                    if (data.result) {
                        window.location.href = "/estadoPedidos";
                    }
                } catch (err) {
                    console.log('Ocurrió un error en al registrar el pedido: ' + err);
                }
            } else {
                try {
                    const url = process.env.REACT_APP_SERVER + '/api/v1/pedidos/register';
                    const alertaURL = process.env.REACT_APP_SERVER + '/api/v1/pedidos/alert';

                    const body = {
                        medio_de_pago: singleMetodoPago,
                        medio_de_entrega: singleMedioEntrega,
                        items: arrayProductos,
                        soportePago: objPedidoSoportePago,
                        transporte,
                        sucursal: singleSucursal,
                        punto_envio: singlePuntoEnvio,
                        valor_final: subtotal + impuesto + Math.round(transporte),
                        fecha_recoge: fecha_recoge === '' ? moment().format('YYYY-MM-DD') : fecha_recoge,
                        departamento: objCodDatosEntrega.id_departamento,
                        ciudad: objCodDatosEntrega.id_ciudad,
                        direccion: direcccionFinal,
                        merchantId: process.env.REACT_APP_MERCHANT_ID,
                        currency: process.env.REACT_APP_CURRENCY,
                        telefono: user.telefono,
                        observaciones: observacion
                    }

                    await axios.post(alertaURL, body, {
                        headers: {
                            'Authorization': `Bearer ${accessToken}`
                        }
                    });
                    const { data } = await axios.post(url, body, {
                        headers: {
                            'Authorization': `Bearer ${accessToken}`
                        }
                    });

                    if (data.result) {
                        mostrarAlert('success', 'title', data.message);

                        if (configCupon && cupon.descuentoAplicado) {
                            const urlRestarCupon = process.env.REACT_APP_SERVER + '/api/v1/discounts/cupones/restar';
                            axios.post(urlRestarCupon, { cupon: cupon, id_pedido: data.id_pedido }, {
                                headers: {
                                    'Authorization': `Bearer ${accessToken}`
                                }
                            });
                        }

                        if (singleMetodoPago === 'Pasarela de Pagos GOU') {
                            let ipAddress = '';

                            try {
                                const urlIP = 'https://www.cloudflare.com/cdn-cgi/trace';
                                const { data: ipData } = await axios.get(urlIP);
                                let ip = ipData.split('\n')
                                ip = ip[2].split('=')
                                ip = ip[1];
                                ipAddress = ip;
                            } catch (err) {
                                const urlIP2 = 'https://geolocation-db.com/json/';
                                const { data: ipData } = await axios.get(urlIP2);
                                ipAddress = ipData.IPv4;
                            }

                            let itemsPayment = [];
                            arrayProductos.forEach(productos => {
                                itemsPayment.push({
                                    "sku": productos.item,
                                    "name": productos.descripcion_item,
                                    "category": "physical",
                                    "qty": productos.cantidad,
                                    "price": productos.total,
                                    "tax": productos.impuesto
                                })
                            });

                            const payment = {
                                "auth": {
                                    "login": data.login,
                                    "tranKey": data.tranKey,
                                    "nonce": data.nonce,
                                    "seed": data.seed
                                },
                                "payment": {
                                    "reference": data.numero_pedido,
                                    "description": "Pedido " + data.numero_pedido + " - Medio Entrega: " + singleMedioEntrega,
                                    "amount": {
                                        "currency": process.env.REACT_APP_CURRENCY,
                                        "total": (subtotal + impuesto + Math.round(transporte)),
                                        "taxes": [
                                            {
                                                "kind": "valueAddedTax",
                                                "amount": impuesto,
                                                "base": subtotal
                                            }
                                        ],
                                        "details": [
                                            {
                                                "kind": "subtotal",
                                                "amount": subtotal
                                            },
                                            {
                                                "kind": "shipping",
                                                "amount": Math.round(transporte)
                                            }
                                        ]
                                    },
                                    "items": itemsPayment
                                },
                                "buyer": {
                                    "documentType": user.tipo_documento,
                                    "document": user.numero_identificacion,
                                    "name": user.nombre,
                                    "surname": user.apellido_1,
                                    "mobile": user.telefono,
                                    "email": user.email
                                },
                                "expiration": data.expiration,
                                "returnUrl": process.env.REACT_APP_RESPONSE_URL,
                                "cancelUrl": process.env.REACT_APP_RESPONSE_URL,
                                "ipAddress": ipAddress,
                                "locale": 'es_CO',
                                "userAgent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Safari/537.36"
                            };

                            const urlCheckout = process.env.REACT_APP_BASE_URL_GOU + '/api/session/';
                            const { data: responseData, status } = await axios.post(urlCheckout, payment);

                            if (status === 200) {
                                cookies.set('requestId', responseData.requestId, { path: '/', maxAge: 3600 });

                                const urlTransaccion = process.env.REACT_APP_SERVER + '/api/v1/pedidos/transaccion/insert';
                                await axios.post(urlTransaccion, { id_sesion_gou: responseData.requestId, pedido: data.numero_pedido }, {
                                    headers: {
                                        'Authorization': `Bearer ${accessToken}`
                                    }
                                });

                                window.location.href = responseData.processUrl;
                            } else {
                                window.location.href = "/estadoPedidos";
                            }
                        } else {
                            if (singleMetodoPago === 'Sistecredito(Redirección a WhatsApp)') {

                                let mensaje = `Mi pedido es el número ${data.numero_pedido} y seleccioné el medio de pago Sistecredito`;
                                window.open(`https://wa.me/+573182462095?text=${mensaje}`, "_blank");
                                window.location.href = "/estadoPedidos";
                            } else if (singleMetodoPago === 'Pago contra entrega') {
                                window.location.href = "/estadoPedidos";
                            } else {
                                if (tipoCliente === 'B2B') {
                                    let objPedido = {
                                        estado_pedido: 'Aprobado',
                                        usuario: 'b2b2c',
                                        id_pedido: data.id_pedido,
                                        id_cliente: data.id_cliente
                                    }
                                    const urlAprobar = process.env.REACT_APP_SERVER + '/api/v1/pedidos/edit';
                                    axios.post(urlAprobar, objPedido);
                                }
                                window.location.href = "/estadoPedidos";
                            }
                        }
                    } else {
                        mostrarAlert('error', 'title', data.message);
                        document.getElementById('btnPedido').disabled = false;
                    }

                    setDepartamento([]);
                    setCiudad([]);
                    setDireccion('');
                    queryFetch();
                } catch (err) {
                    console.log('Ocurrió un error en la consulta de hacer pedido: ' + err);
                    mostrarAlert('error', 'title', 'Ocurrió un error finalizando el pedido, intenta nuevamente');
                }
            }
        } else {
            mostrarAlert('error', 'Advertencia', mensajeValidacion);
        }
    }

    const queryDeleteItem = async (id) => {
        try {
            const cookies = new Cookies();
            if (cookies.get('carrito') > 0) {
                cookies.set('carrito', cookies.get('carrito') - 1, { path: "/" });
            } else {
                cookies.set('carrito', 0, { path: "/" });
            }
            const url = process.env.REACT_APP_SERVER + '/api/v1/carrito/eliminarItem';
            const body = { id: id }
            await axios.post(url, body);
            queryFetch();
        } catch (err) {
            console.log('Ocurrio un error en la consulta de eliminar item: ' + err);
        }
    }
    const queryDeleteItemCarrito = async (cliente) => {
        try {
            const cookies = new Cookies();
            cookies.set('carrito', 0, { path: "/" });
            const url = process.env.REACT_APP_SERVER + '/api/v1/carrito/eliminarItemCarrito';
            const body = { cliente: String(cliente) }
            await axios.post(url, body);
            queryFetch();
        } catch (err) {
            console.log('Ocurrio un error en la consulta de eliminar item: ' + err);
        }
    }

    const handleMetodoPago = e => {
        setSingleMetodoPago(e.target.value)
        if (tipoCliente === 'B2C') {
            if (e.target.value === 'Enviar soporte') {
                setShowMedioPago(true)
            }
        }
    }

    const handleSucursal = e => {
        setSingleSucursal(e.target.value);
        queryPuntosEnvio(e.target.value, subtotal + impuesto, arrayProductos);
    }

    const handleDatosEntrega = (objPuntoEnvio, valorDeclaradoApi, itemsApi) => {
        setDepartamento(objPuntoEnvio.departamento);
        setCiudad(objPuntoEnvio.ciudad);
        setDireccion(objPuntoEnvio.direccion);

        let objAux = {
            id_departamento: objPuntoEnvio.id_departamento,
            id_ciudad: objPuntoEnvio.id_ciudad,
            nombre_ciudad: objPuntoEnvio.ciudad,
            nombre_departamento: objPuntoEnvio.departamento
        };
        setObjCodDatosEntrega(objAux);
        queryPrecioTransporte(itemsApi, valorDeclaradoApi, objPuntoEnvio.id_ciudad, objPuntoEnvio.id_departamento, document.getElementById('medioEntrega').value);
    }

    const handlePuntoEnvio = e => {
        setSinglePuntoEnvio(e.target.value);

        for (let i = 0; i < arrayPuntosEnvio.length; i++) {
            if (arrayPuntosEnvio[i].codigo === e.target.value) {
                handleDatosEntrega(arrayPuntosEnvio[i], subtotal + impuesto, arrayProductos);
            }
        }

    }

    const handleMedioEntrega = async e => {
        setSingleMedioEntrega(e.target.value);
        queryPrecioTransporte(arrayProductos, (subtotal + impuesto), objCodDatosEntrega.id_ciudad, objCodDatosEntrega.id_departamento, e.target.value)

    }

    const handleFechaRecoger = e => {
        if (new Date(moment().format('YYYY-MM-DD')) <= new Date(e.target.value) && new Date(e.target.value) <= new Date(moment().add(+7, 'day').format('YYYY-MM-DD'))) {
            setFecha_recoge(e.target.value);
        } else {
            mostrarAlert('error', 'Advertencia', 'La fecha debe estar en un rango de 7 días a la fecha actual');
        }
    }
    const handleObservacion = e => {
        setObservacion(e.target.value);
    }

    const handleModificarCantidad = async () => {

        const cookies = new Cookies();
        const accessToken = cookies.get('accesToken');

        try {
            if (cantidad % 1 !== 0) {
                mostrarAlert('error', 'Advertencia', 'La cantidad debe de ser un número entero');
            } else if (cantidad <= 0) {
                mostrarAlert('error', 'Advertencia', 'La cantidad debe ser mayor a 0');
            } else {
                let respuesta = true;
                if (user.lista_de_precios === "001") {
                    const urlCargue = process.env.REACT_APP_SERVER + '/api/v1/customers/consultCargue';
                    const responseCargue = await axios.post(urlCargue, { id_cliente: user.id_cliente, item: objModificarCantidad.item, cantidad: cantidad });
                    let dataCargue = responseCargue.data;
                    if (dataCargue.respuesta !== '') {
                        respuesta = window.confirm(dataCargue.respuesta);
                    }
                }

                if (respuesta) {

                    const urlDescuentosVolumen = process.env.REACT_APP_SERVER + '/api/v1/discountsVolume';
                    const bodyDescuentosVolumen = { item: objModificarCantidad.item, cantidad: cantidad, descuento: objModificarCantidad.descuento }

                    const responseDescuentosVolumen = await axios.post(urlDescuentosVolumen, bodyDescuentosVolumen, {
                        headers: {
                            'Authorization': `Bearer ${accessToken}`
                        }
                    });

                    const url = process.env.REACT_APP_SERVER + '/api/v1/shoppingCart/edit';
                    const body = { id: objModificarCantidad.id, descuento: responseDescuentosVolumen.data.descuentoFinal, cantidad: cantidad };
                    const { data } = await axios.post(url, body);


                    if (data.result) {
                        queryFetch();
                    }

                    setShowModificarCantidad(false);
                }
            }
        } catch (err) {
            console.log('Error en la consulta de modificación de cantidad: ' + err);
        }
    }

    const handleInputModificarCantidad = (e) => {
        setCantidad(e.target.value)
    }

    const subirPdfSoportePago = async (fileSoportePagos) => {

        const cookies = new Cookies();
        const accessToken = cookies.get('accesToken');
        if (fileSoportePagos.length > 0) {
            document.getElementById("fileSoportePago").classList.remove("is-invalid");
            document.getElementById("fileSoportePago").classList.add("is-valid");
            setMensajeValidacionArchivo("");
            try {
                const url = process.env.REACT_APP_SERVER + '/api/v1/pedidos/consult/codPaidSupport';
                const { data } = await axios.post(url);
                let codSoporte = data;

                let formData = new FormData();
                for (let value of fileSoportePagos) {
                    formData.append("formData", value);
                }
                const response = await axios.post(process.env.REACT_APP_SERVER + "/api/v1/pdf/soporte_pago", formData, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    }
                })
                cookies.set('accessToken', response.data.accessToken, { path: "/" });

                let arrayName = fileSoportePagos[0].name.split('.');
                let extension = "." + arrayName[arrayName.length - 1];
                if (response.data.result) {
                    let objPedidoSoportePagoAux = {
                        codigo_soporte_pago: codSoporte.cod,
                        url_soporte_pago: "SoportePago-" + codSoporte.cod + extension
                    };

                    setObjPedidoSoportePago(objPedidoSoportePagoAux)
                } else {
                    setMensajeValidacionArchivo(response.data.message);
                }

            } catch (err) {
                console.log('Ocurrió error al subir el archivo: ' + err);
            }
        } else {
            document.getElementById("fileSoportePago").classList.remove("is-valid");
            document.getElementById("fileSoportePago").classList.add("is-invalid");
            setMensajeValidacionArchivo("Debe de seleccionar un archivo");
        }

    }
    const handleCotizacion = async () => {
        const cookies = new Cookies();
        const accessToken = cookies.get('accesToken');
        const user = cookies.get('user');
        let cantidadCotizaciones = 1;

        //Consulta el consecutivo  de cotizaciones
        const urlConsulta = process.env.REACT_APP_SERVER + '/api/v1/checkQuote';
        const responseConsulta = await axios.get(urlConsulta, {
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        });
        let dataConsulta = responseConsulta.data[0].cantidad_cotizaciones;

        if (dataConsulta == null) {
            cantidadCotizaciones = 1;
        } else {
            cantidadCotizaciones = parseInt(dataConsulta) + 1;
        }

        //Inserta la cotizacion y genera local el pdf
        const url = process.env.REACT_APP_SERVER + '/api/v1/cotizacion';
        const body = {
            id: user.id_cliente,
            numeroCotizacion: cantidadCotizaciones,
            nombre: user.nombre,
            numero_identificacion: user.numero_identificacion,
            lista_precio: user.lista_de_precios,
            medio_de_pago: singleMetodoPago,
            medio_de_entrega: singleMedioEntrega,
            items: arrayProductos,
            soportePago: objPedidoSoportePago,
            transporte,
            sucursal: singleSucursal,
            punto_envio: singlePuntoEnvio,
            valor_final: subtotal + impuesto + Math.round(transporte),
            fecha_recoge: fecha_recoge === '' ? moment().format('YYYY-MM-DD') : fecha_recoge,
            departamento: objCodDatosEntrega.id_departamento,
            ciudad: objCodDatosEntrega.id_ciudad,
            nombre_departamento: objCodDatosEntrega.nombre_departamento,
            nombre_ciudad: objCodDatosEntrega.nombre_ciudad,
            direccion,
            merchantId: process.env.REACT_APP_MERCHANT_ID,
            currency: process.env.REACT_APP_CURRENCY,
            observaciones: observacion,
            usuario_creacion: user.nombre + " " + user.apellido_1 + " " + user.apellido_2,
            telefono: user.telefono,
            barrio: user.barrio

        }
        const response = await axios.post(url, body, {
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        });

        const dataDirrecionLocal = response;
        let objData = {
            filename: dataDirrecionLocal.data,
            numero: cantidadCotizaciones
        }
        //Sube la cotizacion al servidor FTP
        const responsePdf = await axios.post(process.env.REACT_APP_SERVER + "/api/v1/pdf/cotizacion", objData, {
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        });
        const PDFSubido = responsePdf.data;
        if (PDFSubido.result) {
            //Registra la URL del FTP en la Base de datos
            const realcionPDF = await axios.post(process.env.REACT_APP_SERVER + "/api/v1/registrar_cotizacion", PDFSubido.objDatos, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });

            let direccionFTP = realcionPDF.data.resultado;
            if (realcionPDF.data) {
                //Abre en una nueva pestaña la cotización
                window.open(direccionFTP, '_blank');
            } else {
                mostrarAlert('error', 'Advertencia', 'Hay un error en el registro del archivo');
            }
        }

    }

    const changeAceptacion = async (e) => {
        if (e.target.checked) {

            document.getElementById('btnPedido').disabled = false;
        } else {
            document.getElementById('btnPedido').disabled = true;
        }

    }

    const cerrarModalMedioPago = () => {
        setShowMedioPago(false);
    }
    const cerrarModalMontarPedido = () => {
        setShowMontarPedido(false);
    }
    const cerrarModalCantidad = () => {
        document.getElementById('selectorPedido' + idPedido).checked = false;
        setShowModalCantidad(false);
    }
    const cerrarModalEspera = () => {
        setShowModalEspera(false);
    }
    const modificarMedioPedido = () => {
        setSingleMetodoPago("Pasarela de Pagos GOU")
        document.getElementById('MedioPago').value = 'Pasarela de Pagos GOU';
        setShowMedioPago(false);
    }
    const validationRoute = async () => {

        const cookies = new Cookies();
        const accessToken = cookies.get('accesToken');
        try {
            const url = process.env.REACT_APP_SERVER + "/requireAuth/routes";
            await axios.get(url, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });

        } catch (error) {
            console.log('La validación presentó error')
            console.log(error);
            cookies.remove('accessToken', { path: "/" });
            cookies.remove('username', { path: "/" });
            cookies.remove('id', { path: "/" });
            window.location.href = "/inicioSesion";
        }
    }

    const montarPedido = async () => {
        buscar();
        setShowMontarPedido(true)
    }
    const AgregarCantidad = async () => {

        let cant = document.getElementById('cantidadProducto').value;

        if (cant === '') {
            cant = '1';
        }
        if (cant % 1 === 0) {
            if (cant === '0') {
                alert('La cantidad debe ser diferente de 0');
            } else {

                if (cant < 0) {
                    alert('La cantidad debe ser mayor a 0');
                } else {
                    let respuesta = true;
                    if (user.lista_de_precios === "001") {
                        const urlCargue = process.env.REACT_APP_SERVER + '/api/v1/customers/consultCargue';
                        const responseCargue = await axios.post(urlCargue, { id_cliente: user.id_cliente, item: itemPedido, cantidad: cant });
                        let dataCargue = responseCargue.data;
                        if (dataCargue.respuesta !== '') {
                            respuesta = window.confirm(dataCargue.respuesta);
                        }
                    }

                    if (respuesta) {
                        arrayItemsPedidos.push(
                            ...objProductos,
                            {
                                items: itemPedido,
                                cantidadPedido: cant
                            });

                        setObjProductos(arrayItemsPedidos)
                        setShowModalCantidad(false)
                    }
                }
            }
        } else {
            alert('La cantidad ingresada deben ser números enteros')
        }
    }

    const AgregarItems = async (item, id) => {

        let estado = document.getElementById('selectorPedido' + id).checked;
        setItemPedido(item)
        setIdPedido(id)

        if (estado) {
            setShowModalCantidad(true)
        } else {
            let indice = objProductos.findIndex(e => e.items === item);
            objProductos.splice(indice, 1)
        }
    }
    const buscar = async () => {
        const cookies = new Cookies();
        const accessToken = cookies.get('accesToken');

        let elements = document.getElementsByClassName("selector");
        for (let i = 0; i < elements.length; i++) {
            elements[i].checked = false;
        }
        document.getElementsByClassName('selector').checked = false;

        const url = process.env.REACT_APP_SERVER + '/api/v1/items/busquedaMontadorPedidos';
        let body = { text: texto, rangoInicial: 0, rangoFinal: 24 };

        const { data } = await axios.post(url, body, {
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        });

        if (data.length !== 0) {
            setArrayDatos(data)
            setDatosBusqueda('none')
        } else {
            setArrayDatos([])
            setDatosBusqueda('revert')
        }
    }

    const RegistraCarrito = async () => {

        setShowMontarPedido(false)

        const cookies = new Cookies();
        const accessToken = cookies.get('accesToken');

        if (objProductos.length > 0) {
            setShowModalEspera(true)
            for (let i = 0; i < objProductos.length; i++) {

                let item_pedido = objProductos[i].items;
                let cantidad = objProductos[i].cantidadPedido;
                let precio = 0;
                let precioFinal = 0;
                let descuentoFinal = 0;
                let total = 0;

                const urlPrecios = process.env.REACT_APP_SERVER + '/api/v1/discounts/prices';
                const bodyPrecios = { item: item_pedido }
                const { data } = await axios.post(urlPrecios, bodyPrecios, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    }
                });

                const urlDescuetnos = process.env.REACT_APP_SERVER + '/api/v1/discounts';
                const bodyDescuentos = { item_pedido: item_pedido }
                const responseDescuentos = await axios.post(urlDescuetnos, bodyDescuentos, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    }
                });

                const urlDescuentosPersonalize = process.env.REACT_APP_SERVER + '/api/v1/discountsVolumePersonalize';
                const bodyDescuentosPersonalize = { item: item_pedido, cantidad: cantidad, descuento: responseDescuentos.data[0].descuentoFinal * 100 };
                const responseeDescuentoPersonalize = await axios.post(urlDescuentosPersonalize, bodyDescuentosPersonalize, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    }
                });

                const urlDescuentosVolumen = process.env.REACT_APP_SERVER + '/api/v1/discountsVolume';
                const bodyDescuentosVolumen = { item: item_pedido, cantidad: cantidad, descuento: responseeDescuentoPersonalize.data.descuentoFinal };
                const responseeDescuentoVolumen = await axios.post(urlDescuentosVolumen, bodyDescuentosVolumen, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    }
                });
                precio = data[0].precio;
                precioFinal = data[0].precio * (1 - responseeDescuentoVolumen.data.descuentoFinal / 100)
                descuentoFinal = (responseeDescuentoVolumen.data.descuentoFinal).toFixed(2);
                total = precioFinal * cantidad

                const objItem = {
                    item: item_pedido,
                    um: data[0].unidad_de_medida,
                    cantidad: cantidad,
                    precio: Math.round(precio),
                    descuento_original: descuentoFinal,
                    descuento: descuentoFinal,
                    valor_final: total,
                };

                const url = process.env.REACT_APP_SERVER + '/api/v1/shoppingCart/register'
                const body = { objItem, cantidad: objItem.cantidad };
                await axios.post(url, body, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    }
                });
            }
            setShowModalEspera(false)
            window.location.reload();
        } else {
            cerrarModalMontarPedido()
        }
    }

    useEffect(() => {

        queryListPrice();
        queryMetodoPago();
        queryFetch();
        queryMedioEntrega();
        // eslint-disable-next-line
    }, []);
    validationRoute();

    function mostrarAlert(icono, titulo, mensaje) {
        Swal.fire({
            icon: icono,
            title: mensaje
        });

    }
    const eventoEnter = (e) => {
        if (e.code === 'Enter') {
            buscar();
        }
    }

    const [arrayDepartamentos, setArrayDepartamentos] = useState([]);
    const [arrayCiudades, setArrayCiudades] = useState([]);
    const [mensajeValidacionTelefono, setMensajeValidacionTelefono] = useState();
    const [mensajeValidacionPassword, setMensajeValidacionPassword] = useState();
    const [mensajeValidacionEmail, setMensajeValidacionEmail] = useState();
    const [mensajeValidacionNombre, setMensajeValidacionNombre] = useState();
    const [mensajeValidacionApellido, setMensajeValidacionApellido] = useState();
    const [mensajeValidacionDireccion, setMensajeValidacionDireccion] = useState();
    const [mensajeValidacionTerminos, setMensajeValidacionTerminos] = useState();
    const [validated, setValidated] = useState(false);
    const [disabledButton, setDisabledButton] = useState(false);
    const [patternIdentificacion, setPatternIdentificacion] = useState({
        soft: '',
        hard: '',
        name: '',
        lastname: '',
        tooltipName: 'campo solo permite letras mayusculas, letras minusculas, letras con acento'
    });
    const patterns = {
        '': { soft: '', hard: '', name: '', lastname: '', tooltipName: '' },
        'CC': { soft: '^[1-9][0-9]{0,9}$', hard: '^[1-9][0-9]{3,9}$', name: '^[a-zA-ZáéíóúüñÁÉÍÓÚÜÑ ]{0,40}$', lastname: '', tooltipName: 'campo solo permite letras mayusculas, letras minusculas, letras con acento' },
        'CE': { soft: '^[a-zA-Z1-9][a-zA-Z0-9]{0,7}$', hard: '^[a-zA-Z1-9][a-zA-Z0-9]{3,7}$', name: '^[a-zA-ZáéíóúüñÁÉÍÓÚÜÑ ]{0,40}$', lastname: '', tooltipName: 'campo solo permite letras mayusculas, letras minusculas, letras con acento' },
        'TI': { soft: '^[1-9][0-9]{0,11}$', hard: '^[1-9][0-9]{4,11}$', name: '^[a-zA-ZáéíóúüñÁÉÍÓÚÜÑ ]{0,40}$', lastname: '', tooltipName: 'campo solo permite letras mayusculas, letras minusculas, letras con acento' },
        'NIT': { soft: '^[1-9][0-9]{0,9}$', hard: '^[1-9][0-9]{6,9}$', name: '^[a-zA-Z0-9][a-zA-Z1-9 ]{0,40}$', lastname: 'ocultar', tooltipName: 'campo solo permite números, letras mayusculas, letras minusculas' },
        'RUT': { soft: '^[1-9][0-9]{0,9}$', hard: '^[1-9][0-9]{6,9}$', name: '^[a-zA-Z0-9][a-zA-Z1-9 ]{0,40}$', lastname: 'ocultar', tooltipName: 'campo solo permite números, letras mayusculas, letras minusculas' }
    };

    const [objFormulario, setObjFormulario] = useState({
        tipo_documento: "",
        numero_identificacion: "",
        nombre: "",
        apellido_1: "",
        apellido_2: "",
        direccion: "",
        departamento: "",
        ciudad: "",
        barrio: "",
        telefono: "",
        email: "",
        terminos_condiciones: "No acepto",
        password_cliente: "",
        codigo_vendedor: "MPMA",
        condicion_de_pago: "001",
        tipo_de_cliente: "0009",
        grupo_descuento: "0032",
        lista_de_precios: "014",
        difusion_publicitaria_cliente: "",
        tipo_cliente_pagina: "B2C",
        tipo_descuento_cliente: "Cliente B2C",
        estado_cliente: "1",
        sucursal: "001",
    });

    const location = useLocation();

    const changeFormulario = (e) => {
        let { name, value } = e.target;
        if (name === 'terminos_condiciones') {
            if (objFormulario.terminos_condiciones === 'No acepto') {
                setObjFormulario({
                    ...objFormulario,
                    [name]: value
                });
            } else if (objFormulario.terminos_condiciones === 'Acepto') {
                setObjFormulario({
                    ...objFormulario,
                    [name]: 'No acepto'
                });
            }
            setMensajeValidacionTerminos('');
        } else if (name === 'tipo_documento') {
            setObjFormulario({
                ...objFormulario,
                numero_identificacion: '',
                nombre: '',
                [name]: value
            });
            setPatternIdentificacion(patterns[value]);
            setMensajeValidacionNombre('');
            setMensajeValidacionApellido('');
        } else {
            setObjFormulario({
                ...objFormulario,
                [name]: value
            });
        }
    }

    const consultarDepartamentos = async () => {
        try {
            const url = process.env.REACT_APP_SERVER + '/api/v1/customers/departments';
            const response = await axios.get(url);
            const data = response.data;

            let arrayDepartamentoAux = [{ name: "", id: "" }];

            for (let index = 0; index < data.length; index++) {
                arrayDepartamentoAux.push({
                    name: data[index].departamento,
                    id: data[index].id
                });
            }
            setArrayDepartamentos(arrayDepartamentoAux);
        } catch (error) {
            console.log(error);
        }
    }

    const consultarCiudades = async (idDepartamento) => {
        try {
            const url = process.env.REACT_APP_SERVER + '/api/v1/customers/cities/' + idDepartamento;
            const response = await axios.get(url);
            const data = response.data;

            let arrayCiudadesAux = [{ name: "", id: "" }];

            for (let index = 0; index < data.length; index++) {
                arrayCiudadesAux.push({
                    name: data[index].f013_descripcion,
                    id: data[index].f013_id
                });
            }
            setArrayCiudades(arrayCiudadesAux);
        } catch (error) {
            console.log(error);
        }
    }

    const changeDepartamentos = (e) => {
        consultarCiudades(e.target.value);
        queryPrecioTransporteCliente(arrayProductos, (subtotal + impuesto), objFormulario.ciudad, e.target.value, singleMedioEntrega)
        changeFormulario(e);
    }

    const changeCiudades = (e) => {
        queryPrecioTransporteCliente(arrayProductos, (subtotal + impuesto), e.target.value, objFormulario.departamento, singleMedioEntrega)
        changeFormulario(e);
    }

    const queryPrecioTransporteCliente = async (items, valorDeclarado, ciudad2, departamento2, medioEntrega) => {
        const cookies = new Cookies();
        const accessToken = cookies.get('accesToken');

        try {
            if (medioEntrega === 'Entrega') {

                let concatCiudad = `${departamento2}${ciudad2}`;
                if ((concatCiudad === '76111') || (concatCiudad === '76520') || (concatCiudad === '76834')) {
                    setDisabledButton(true);
                    const url = process.env.REACT_APP_SERVER + '/api/v1/envio/despacho';
                    const body = { items, departamento: departamento2, ciudad_destino: ciudad2 }
                    const { data } = await axios.post(url, body, {
                        headers: {
                            'Authorization': `Bearer ${accessToken}`
                        }
                    });
                    setTransporte(data[0]);
                    setDisabledButton(false);
                } else {
                    setDisabledButton(true);
                    const url = process.env.REACT_APP_SERVER + '/api/v1/cotizarEnvio';
                    const body = { items: items, valorDeclarado, ciudad: ciudad2, departamento: departamento2 };

                    const { data } = await axios.post(url, body, {
                        headers: {
                            'Authorization': `Bearer ${accessToken}`
                        }
                    });

                    setTransporte(data[0]);
                    setDisabledButton(false);
                }
            } else {
                setTransporte(0);
            }
        } catch (err) {
            console.log('Ocurrió error en la consulta de precio transporte:' + err);
        }
    }

    const validacionTelefono = (e) => {
        const { value } = e.target;
        let mensaje = '';
        let validacion = true;
        if (value.length !== 10) {
            validacion = false;
            mensaje = 'El campo debe contener 10 dígitos';
        }
        setMensajeValidacionTelefono(mensaje)

        if (!validacion) {
            document.getElementById("telefono").classList.remove("is-valid");
            document.getElementById("telefono").classList.add("is-invalid");
            document.getElementById("telefono").setCustomValidity("invalid");
        } else {
            document.getElementById("telefono").classList.remove("is-invalid");
            document.getElementById("telefono").classList.add("is-valid");
            document.getElementById("telefono").setCustomValidity("");
        }
        changeFormulario(e);
    }

    const changeDireccion = (e) => {
        const { value } = e.target;
        if (value === "") {
            changeFormulario(e);
        } else {
            let regex = new RegExp("^[a-zA-Z0-9 ]+$");
            if (regex.test(value)) {
                changeFormulario(e);
            }
        }
        setMensajeValidacionDireccion('');
    }

    const changeEmail = (e) => {
        let validacion = true;
        const { value } = e.target;
        if (value === "") {
            validacion = false;
        }
        if (value.indexOf('@') === -1 || value.indexOf('.') === -1) {
            validacion = false;
        }
        // Verificar que el '@' esté antes del último '.'
        if (value.lastIndexOf('@') > value.lastIndexOf('.')) {
            validacion = false;
        }
        // Verificar que hay caracteres antes del '@' y después del último '.'
        if (value.indexOf('@') === 0 || value.lastIndexOf('.') === value.length - 1) {
            validacion = false;
        }
        // Verificar que haya al menos un carácter entre el '@' y el último '.'
        if (value.lastIndexOf('.') - value.indexOf('@') <= 1) {
            validacion = false;
        }
        // Verificar que el texto después del último punto tenga más de dos caracteres
        let ultimoPuntoIndex = value.lastIndexOf('.');
        let textoDespuesPunto = value.substring(ultimoPuntoIndex + 1);
        if (textoDespuesPunto.length <= 1) {
            validacion = false;
        }
        if (!validacion) {
            document.getElementById("email").classList.remove("is-valid");
            document.getElementById("email").classList.add("is-invalid");
            document.getElementById("email").setCustomValidity("invalid");
            setMensajeValidacionEmail('Debe ingresar un correo valido');
        } else {
            document.getElementById("email").classList.remove("is-invalid");
            document.getElementById("email").classList.add("is-valid");
            document.getElementById("email").setCustomValidity("");
            setMensajeValidacionEmail('');
        }
        changeFormulario(e);
    }

    const changeIdentificacion = (e) => {
        const { value } = e.target;
        if (value === "") {
            changeFormulario(e);
        } else {
            let regex = new RegExp(patternIdentificacion.soft);
            if (regex.test(value)) {
                changeFormulario(e);
            }
        }
    }

    const changeNombre = (e) => {
        const { value } = e.target;
        if (value === "") {
            changeFormulario(e);
        } else {
            let regex = new RegExp(patternIdentificacion.name);
            if (regex.test(value)) {
                changeFormulario(e);
            }
        }
        setMensajeValidacionNombre('');
        setMensajeValidacionApellido('');
    }

    const mostrarPassword = () => {
        var campo = document.getElementById("password_cliente");
        if (campo.type === "password") {
            campo.type = "text";
            document.getElementById("iconoOcultar").style = "display:none";
            document.getElementById("iconoMostrar").style = "display:block";
        } else {
            campo.type = "password";
            document.getElementById("iconoOcultar").style = "display:block";
            document.getElementById("iconoMostrar").style = "display:none";
        }
    }

    const blurPassword = (e) => {
        changeFormulario(e);
        let letras_minusculas = "abcdefghyjklmnñopqrstuvwxyz";
        let letras_mayusculas = "ABCDEFGHYJKLMNÑOPQRSTUVWXYZ";
        let numeros = "0123456789";
        let simbolos = "!/%#$*";

        const { value } = e.target;
        let validacion = false;
        let mensaje = "";

        if (value !== "") {
            //valida la longitud
            if (value.length < 12) {
                mensaje = "Debe de contener minímo 12 carácteres";
            } else {
                //valida letra minusculas
                if (validacionCaracteres(value, letras_minusculas)) {
                    //valida letra mayúscula
                    if (validacionCaracteres(value, letras_mayusculas)) {
                        //valida números
                        if (validacionCaracteres(value, numeros)) {
                            //valida simbolo 
                            if (validacionCaracteres(value, simbolos)) {
                                validacion = true;
                            } else {
                                mensaje = "Debe de contener al menos 1 de los siguientes carácteres especiales (!, /, %, #, $, *)";
                            }
                        } else {
                            mensaje = "Debe de contener al menos 1 número";
                        }
                    } else {
                        mensaje = "Debe de contener al menos 1 letra mayúscula";
                    }
                } else {
                    mensaje = "Debe de contener al menos 1 letra minúscula";
                }
            }
        } else {
            validacion = true;
        }

        if (!validacion) {
            document.getElementById("password_cliente").classList.remove("is-valid");
            document.getElementById("password_cliente").classList.add("is-invalid");
            document.getElementById("password_cliente").setCustomValidity("invalid");
        } else {
            document.getElementById("password_cliente").classList.remove("is-invalid");
            document.getElementById("password_cliente").classList.add("is-valid");
            document.getElementById("password_cliente").setCustomValidity("");
        }
        setMensajeValidacionPassword(mensaje);
    }

    const validacionCaracteres = (texto, caracteres) => {
        for (let i = 0; i < texto.length; i++) {
            if (caracteres.indexOf(texto.charAt(i), 0) !== -1) {
                return true;
            }
        }
        return false;
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (!form.checkValidity()) {
            event.preventDefault();
            event.stopPropagation();
            if (objFormulario.telefono === '') {
                setMensajeValidacionTelefono('El campo debe contener 10 dígitos');
            }
            if (objFormulario.email === '') {
                setMensajeValidacionEmail('Debe ingresar un correo valido');
            }
            if (objFormulario.nombre === '') {
                setMensajeValidacionNombre(patternIdentificacion.tooltipName);
            }
            if (objFormulario.apellido_1 === '') {
                setMensajeValidacionApellido(patternIdentificacion.tooltipName);
            }
            if (objFormulario.terminos_condiciones === 'No acepto') {
                setMensajeValidacionTerminos('Debe aceptar los términos y condiciones');
            }
            if (objFormulario.direccion === '') {
                setMensajeValidacionDireccion('campo solo permite números, letras mayusculas, letras minusculas')
            }
        } else {
            if (objFormulario.numero_identificacion < 0) {
                alert('El número de identificación no debe ser negativo')
            } else {
                if (arrayProductos.length === 0) {
                    alert('Debe de agregar al menos un item al carrito');
                } else {
                    if (location.pathname === '/carrito') {
                        setDisabledButton(true);
                        const accessToken = cookies.get('accesToken');
                        const url = process.env.REACT_APP_SERVER + '/api/v1/customers/registerPorIpCarrito';
                        const { data } = await axios.post(url, objFormulario, {
                            headers: {
                                'Authorization': `Bearer ${accessToken}`
                            }
                        });

                        if (data.result) {
                            setDepartamento(objFormulario.departamento)
                            setCiudad(objFormulario.ciudad)
                            setDireccion(objFormulario.direccion)
                            cookies.set('accesToken', data.accesToken, { path: '/' });
                            cookies.set('user', data.usuario[0], { path: '/' });
                            console.log(data.message);
                            await queryPedido();

                        } else {
                            console.log("Error al registrar el pedido y cliente");
                            setDisabledButton(false);
                        }
                    }
                }
            }
        }
        setValidated(true);
    }

    useEffect(() => {
        consultarDepartamentos();
    })

    return (
        <>
            <Header />
            <Container>
                <div style={{ display: visualizacionMontadorPedido }}>
                    <Button className='btn btn-warning' id='btnMontador' variant="default" onClick={() => montarPedido()}>Montar Pedido</Button>
                    <br />
                </div>

                <Modal
                    show={showModalMontarPedido}

                    onHide={cerrarModalMontarPedido}
                    size="lg"
                    fullscreen={'sm-down'}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>MONTADOR DE PEDIDOS</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <InputGroup className='contenidoTexto'>
                            <Form.Control placeholder="Busca tu producto" className="rounded-left textBuscador" value={texto} onChange={(e) => setTexto(e.target.value)} onKeyUp={(e) => eventoEnter(e)} />
                            <Button id='btnBuscarMontador' onClick={() => buscar()}>Buscar</Button>
                        </InputGroup>
                        <div className='contMontadorPedido'>
                            <Button variant="outline-success" onClick={() => RegistraCarrito()}>Agregar Al Carrito</Button>
                        </div>
                        <hr />
                        <div>
                            <Table>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>ITEMS</th>
                                        <th>DECRIPCIÓN DEL ITEM</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        arrayDatos.map((fila, id) =>
                                            <tr key={id}>
                                                <td><input disabled={fila.stock_b2b > 0 ? false : true} title={fila.stock_b2b > 0 ? "" : "Validar Disponibilidad"} type="checkbox" className="selector" id={'selectorPedido' + id} onClick={() => AgregarItems(fila.item_pedido, id)} /></td>
                                                <td>{fila.item_pedido}</td>
                                                <td>{fila.descripcion_alternativa}</td>
                                            </tr>
                                        )
                                    }
                                    <tr style={{ display: datosBusqueda }}><td colSpan="3">NO HAY ÍTEMS RELACIONADOS</td></tr>
                                </tbody>

                            </Table>
                        </div>
                    </Modal.Body>
                </Modal>
                <br />
                <Modal
                    show={showModalCantidad}
                    backdrop="static"
                    keyboard={false}
                    centered
                    onHide={cerrarModalCantidad}

                >
                    <Modal.Header closeButton>
                        <Modal.Title>Ingresa la cantidad</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Control type='number' id='cantidadProducto' placeholder='Ingresa la cantidad'></Form.Control>
                        <br />
                        <Button variant="outline-warning" onClick={() => AgregarCantidad()}>Aceptar</Button>
                        <Button variant="outline-secondary" onClick={() => cerrarModalCantidad()}>Cancelar</Button>
                    </Modal.Body>
                </Modal>
                <br />
                <Modal
                    show={showModalEspera}
                    backdrop="static"
                    keyboard={false}
                    onHide={cerrarModalEspera}
                    centered

                >
                    <Modal.Body style={{ height: '80px' }}>
                        <div className='spinnerEspera'>

                            <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                className='spannerGrow'
                            />
                            <label><strong>Cargando... </strong></label>
                        </div>

                    </Modal.Body>
                </Modal>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={9} className="mb-3 p-0">
                        <div className="d-none d-sm-block">
                        <Table responsive hover bordered size="sm" className="text-center justify-content-center tablaCarrito">
                            <thead>
                                <tr>
                                    <th>PRODUCTO</th>
                                    <th>CANTIDAD</th>
                                    <th>VALOR UNITARIO</th>
                                    <th>DESCUENTO</th>
                                    <th>IVA</th>
                                    <th>SUBTOTAL</th>
                                    <th>IMPUESTO</th>
                                    <th>TOTAL</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading ?
                                    <tr>
                                        <td colSpan="8" className="text-center">
                                            <Spinner animation="border" variant="warning" style={{ width: '50px', height: '50px' }} />
                                        </td>
                                    </tr>
                                    :
                                    arrayProductos.map((item, i) =>
                                        <tr key={i}>
                                            <td>
                                                <Row>
                                                    <Col xl={5} lg={12} md={12} sm={12}>
                                                        <Link
                                                            to={item.sobre_pedido === 'SI' ? `/productSingleUjueta/${item.referencia}` : `/productSingle/${item.item}`}
                                                            state={{ item_pedido: item.sobre_pedido === 'SI' ? item.referencia : item.item }}
                                                        >
                                                            <Figure.Image
                                                                alt="240x240"
                                                                src={item.image}
                                                            />
                                                        </Link>
                                                        <br></br>
                                                        {item.sobre_pedido === 'SI' && <Button variant="outline-warning" ><small>BAJO PEDIDO</small></Button>}
                                                        <Button variant="outline-danger" onClick={() => queryDeleteItem(item.id)}><Icon.Trash /></Button>
                                                    </Col>
                                                    <Col xl={7} lg={12} md={12} sm={12}>
                                                        <small><b>Item: </b>{item.item}</small>
                                                        <br></br>
                                                        <small style={{ wordWrap: "break-word" }}><b>Descripción: </b>{item.descripcion_item}</small>
                                                        <br></br>
                                                        <small><b>UM: </b>{item.um}</small>
                                                        <br></br>
                                                        <small><b>Referencia: </b>{item.referencia}</small>
                                                    </Col>
                                                </Row>
                                            </td>
                                            <td>
                                                <Form.Group as={Row} className="mb-3 text-center">
                                                    <Col xl={12} lg={12} md={12} sm={12}>
                                                        <Form.Control className="text-center btnCantidad" readOnly type="number" name="cantidad" placeholder="1" min="1" value={item.cantidad} onChange={e => changeCantidad(item.id, e.target.value)} />
                                                        <div className='btnCentrar'>
                                                            <InputGroup >
                                                                <Button onClick={() => handleRestar(item.id, item.cantidad, item.item, item.descuento_original, i)} variant="outline-warning restar">
                                                                    <Icon.Dash />
                                                                </Button>
                                                                <Button onClick={() => handleSumar(item.id, item.cantidad, item.item, item.descuento_original)} variant="outline-warning sumar">
                                                                    <Icon.Plus />
                                                                </Button>
                                                            </InputGroup>
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        <Button variant="outline-warning editar" onClick={() => handleShowModalModificarCantidad(item.id, item.cantidad, item.item, item.descuento_original)}><Icon.PencilSquare /></Button>
                                                    </Col>
                                                </Form.Group>
                                                {item.sobre_pedido === 'SI' &&
                                                    (
                                                        <div colSpan={8} className='text-center text-danger'><Icon.Truck /><small><b> Entrega en 7 días hábiles</b></small></div>
                                                    )
                                                }
                                            </td>
                                            <td>{formatoPesosColombianos.format(item.precio)}</td>
                                            <td>{formatoPorcentaje(item.descuento)}</td>
                                            <td>{formatoPorcentaje(item.iva)}</td>
                                            <td>{formatoPesosColombianos.format(item.subtotal)}</td>
                                            <td>{formatoPesosColombianos.format(item.impuesto)}</td>
                                            <td>{formatoPesosColombianos.format(parseInt(item.total))}</td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </Table>
                        </div>
                        <div className="d-block d-sm-none">
                        {loading ? (
                            <div className="text-center">
                                <Spinner animation="border" variant="warning" style={{ width: '50px', height: '50px' }} />
                            </div>
                        ) : (
                            arrayProductos.map((item, i) => (
                                <Card key={i} className="mb-2">
                                    <Card.Header>
                                        <Row>
                                            <Col xs={12} md={4}>
                                                <Row>
                                                    {item.sobre_pedido === 'SI' && <Button variant="outline-warning" ><small>BAJO PEDIDO</small></Button>}
                                                    <Col xs={8}>
                                                        <small><b>Item: </b>{item.item}</small>
                                                        <br />
                                                        <small style={{ wordWrap: "break-word" }}>{item.descripcion_item}</small>
                                                        <br />
                                                        <small><b>UM: </b>{item.um}</small>
                                                        <br />
                                                        <small><b>Referencia: </b>{item.referencia}</small>
                                                        {item.sobre_pedido === 'SI' && (
                                                            <div className='text-center text-danger'><Icon.Truck /><small><b> Entrega en 7 días hábiles</b></small></div>
                                                        )}
                                                    </Col>
                                                    <Col xs={4} md={4} className="d-flex justify-content-center align-items-center">
                                                        <Link
                                                            to={item.sobre_pedido === 'SI' ? `/productSingleUjueta/${item.referencia}` : `/productSingle/${item.item}`}
                                                            state={{ item_pedido: item.sobre_pedido === 'SI' ? item.referencia : item.item }}
                                                        >
                                                            <Figure.Image
                                                                alt="240x240"
                                                                src={item.image}
                                                                onError={(e) => e.target.src = 'ruta/a/imagen_por_defecto.jpg'}
                                                            />
                                                        </Link>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <hr></hr>
                                        <Row>
                                            <Col xs={8} md={4}>
                                                <Row>
                                                    <Col xs={12}><Row><Col><b>Precio:</b></Col><Col>{formatoPesosColombianos.format(item.precio)}</Col></Row></Col>
                                                    <Col xs={12}><Row><Col><b>Descuento:</b></Col><Col>{formatoPorcentaje(item.descuento)}</Col></Row></Col>
                                                    <Col xs={12}><Row><Col><b>IVA:</b></Col><Col>{formatoPorcentaje(item.iva)}</Col></Row> </Col>
                                                    <Col xs={12}><Row><Col><b>Subtotal:</b></Col><Col>{formatoPesosColombianos.format(item.subtotal)}</Col></Row> </Col>
                                                    <Col xs={12}><Row><Col><b>Impuesto:</b></Col><Col>{formatoPesosColombianos.format(item.impuesto)}</Col></Row> </Col>
                                                    <Col xs={12}><Row><Col><b>Total:</b> </Col><Col>{formatoPesosColombianos.format(parseInt(item.total))}</Col></Row></Col>
                                                </Row>
                                            </Col>
                                            <Col md={6} xs={4}>
                                                <Form.Group as={Row} className="mb-3 text-center">
                                                    <Col xs={12}>
                                                        <Form.Control className="text-center btnCantidad" readOnly type="number" name="cantidad" placeholder="1" min="1" value={item.cantidad} onChange={e => changeCantidad(item.id, e.target.value)} />
                                                        <Button onClick={() => handleRestar(item.id, item.cantidad, item.item, item.descuento_original, i)} variant="outline-warning restar">
                                                            <Icon.Dash />
                                                        </Button>
                                                        <Button onClick={() => handleSumar(item.id, item.cantidad, item.item, item.descuento_original)} variant="outline-warning sumar">
                                                            <Icon.Plus />
                                                        </Button>
                                                    </Col>
                                                    <Col>
                                                        <Button variant="outline-warning" onClick={() => handleShowModalModificarCantidad(item.id, item.cantidad, item.item, item.descuento_original)}><Icon.PencilSquare /></Button>
                                                        <Button variant="outline-danger" onClick={() => queryDeleteItem(item.id)}><Icon.Trash /></Button>
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Card.Header>
                                </Card>
                            ))
                        )}
                        </div>
                        <div className="mb-4 rowColumnRigth" style={{ textAlign: 'center' }}>
                            <Col>
                                <Button variant="outline-danger" onClick={() => queryDeleteItemCarrito(idCliente)}><Icon.Trash /> VACIAR</Button>
                            </Col>
                            <Col >
                                <p className="mensaje">LOS DATOS SE BORRARAN DESPUES DE DOS DÍAS</p>
                            </Col>
                        </div>
                        {
                            imagenBanner.map((fila, id) =>
                                <div className={'banner'} key={id}>
                                    <a href={fila.telefono} target={'_new'}>
                                        <img src={imagenPublicidad + fila.pagina} alt="bannerPrincipal" />
                                    </a>
                                </div>
                            )
                        }
                    </Col>
                    <Modal
                        show={showModificarCantidad}
                        onHide={() => setShowModificarCantidad(false)}
                        backdrop="static"
                        keyboard={false}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Modificar Cantidad</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form.Control type="number" value={cantidad} onChange={handleInputModificarCantidad} />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setShowModificarCantidad(false)}>
                                Cancelar
                            </Button>
                            <Button id="btnAceptar" variant="primary" onClick={() => handleModificarCantidad()}>Aceptar</Button>
                        </Modal.Footer>
                    </Modal>
                    <Col xs={12} sm={12} md={12} lg={3} className="ps-3">
                        {
                            tipoCliente !== 'B2C'
                                ?
                                <Row className="mb-4 rowColumnRigth">
                                    <Col>
                                        <h4>SUCURSAL Y PUNTO DE ENVÍO</h4>
                                        <p>Seleccione una sucursal</p>
                                        <Form.Select onChange={handleSucursal} className="mb-3" placeholder="Seleccione sucursal">
                                            {
                                                arraySucursales.map((item, i) =>
                                                    <option key={i} value={item.codigo}>{item.descripcion}</option>
                                                )
                                            }
                                        </Form.Select>
                                        <p>Seleccione un punto de envío</p>
                                        <Form.Select onChange={handlePuntoEnvio} className="mb-3" placeholder="Seleccione punto envío">
                                            {
                                                arrayPuntosEnvio.map((item, i) =>
                                                    <option key={i} value={item.codigo}>{item.descripcion}</option>
                                                )
                                            }
                                        </Form.Select>
                                    </Col>
                                    <br />
                                </Row>
                                :
                                <Row></Row>
                        }
                        <Row className="mb-4 rowColumnRigth">
                            <Col>
                                <h4>MEDIO DE PAGO</h4>
                                <p>Seleccione un método de pago</p>
                                {
                                    metodoPago.map((item, i) =>
                                        <div key={i}>
                                            {
                                                item.metodo === 'Pasarela de Pagos GOU' ?

                                                    <Row>
                                                        <Col key={0} lg={12} ><img alt='pasarela de pagos GOU' src={"https://placetopay-static-prod-bucket.s3.us-east-2.amazonaws.com/goupagos-com-co/logos/logo_2.svg"} /></Col>
                                                        <Col key={1} lg={3} md={1} sm={1} xs={3}><img alt='PSE' src={imagenPublicidad + "pse.svg"} /></Col>
                                                        <Col key={2} lg={3} md={1} sm={1} xs={3}><img alt='VISA' src={imagenPublicidad + "visa.svg"} /></Col>
                                                        <Col key={3} lg={3} md={1} sm={1} xs={3}><img alt='MASTERCARD' src={imagenPublicidad + "mastercard.svg"} /></Col>
                                                        <Col key={4} lg={3} md={1} sm={1} xs={3}><img alt='AMERICAN EXPRESS' src={imagenPublicidad + "american.svg"} /></Col>
                                                    </Row>

                                                    :
                                                    ''
                                            }
                                        </div>
                                    )
                                }
                                <br></br>
                                <Form.Select id='MedioPago' onChange={handleMetodoPago} className="mb-3" placeholder="Seleccione método de pago">
                                    {
                                        metodoPago.map((item, i) =>
                                            <option key={i} value={item.metodo}>{item.metodo}</option>
                                        )
                                    }
                                </Form.Select>
                                {
                                    singleMetodoPago === 'Enviar soporte' ?
                                        <>
                                            <InputGroup className="mb-3">
                                                <Form.Control
                                                    type="file"
                                                    aria-label="Example text with button addon"
                                                    aria-describedby="basic-addon1"
                                                    placeholder="Agregar soporte pago"
                                                    accept="application/pdf, .jpg, .jpeg"
                                                    id="fileSoportePago"
                                                    onChange={handlePDFSoportePago}
                                                />
                                            </InputGroup>
                                            <small>{mensajeValidacionArchivo === '' ? null : mensajeValidacionArchivo}</small>
                                        </>
                                        :
                                        null
                                }
                                <Modal
                                    show={showModalMedioPago}
                                    backdrop="static"
                                    keyboard={false}
                                    onHide={cerrarModalMedioPago}
                                >
                                    <Modal.Header closeButton>
                                        <Modal.Title>Información</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        Tu transacción se reflejará en un plazo de 24 horas. Para mayor agilidad en el proceso de pago, la transacción se reflejará de manera inmediata con el medio Pasarela de Pagos GOU.
                                    </Modal.Body>
                                    <Modal.Footer>
                                        ¿Desea cambiar su medio de pago a Pasarela de Pagos GOU?
                                        <Button onClick={modificarMedioPedido} variant="outline-success">Si</Button>
                                        <Button onClick={cerrarModalMedioPago} variant="outline-danger">No</Button>
                                    </Modal.Footer>
                                </Modal>

                            </Col>
                            <br />
                        </Row>

                        <Row className="mb-4 rowColumnRigth">
                            <Col>
                                <h4>MEDIO DE ENTREGA</h4>
                                <Form.Select id='medioEntrega' onChange={handleMedioEntrega} className="mb-3" placeholder="Elija medio de entrega" aria-label="Default select example">
                                    {
                                        medioEntrega.map((item, i) =>
                                            <option key={i} value={item.medio}>{item.medio}</option>
                                        )

                                    }
                                </Form.Select>
                                {
                                    singleMedioEntrega === 'Recoge en tienda' || singleMedioEntrega === 'Recoge' || singleMedioEntrega === 'Recoge en bodega'
                                        ?
                                        <>
                                            <p>¿Cuando recogerás el pedido?</p>
                                            <Form.Control className="mb-3" type="date" value={fecha_recoge} min={moment().format('YYYY-MM-DD')} max={moment().add(7, 'days').format('YYYY-MM-DD')} onChange={handleFechaRecoger} required />
                                        </>
                                        :
                                        <>
                                            <div className="mb-3">
                                                <Form.Control value={departamento} hidden={departamento === ""} readOnly className="mb-3" type="text" placeholder="Departamento" />
                                            </div>
                                            <div className="mb-3">
                                                <Form.Control value={ciudad} readOnly hidden={ciudad === ""} className="mb-3" type="text" placeholder="Ciudad" />
                                            </div>
                                            <div className="mb-3">
                                                <Form.Control value={direccion} readOnly hidden={direccion === ""} className="mb-3" type="text" placeholder="Dirección" />
                                            </div>
                                        </>
                                }


                            </Col>
                            <br />
                        </Row>
                        {configCupon &&
                            <Row className="pb-3 rowColumnRigth">
                                <Col className='text-center'>
                                    <h4>CUPÓN DE DESCUENTO</h4>
                                    <InputGroup>

                                        <Form.Control
                                            type="text"
                                            value={cupon.codigoDescuento}
                                            onChange={(e) => setCupon({ ...cupon, codigoDescuento: e.target.value })}
                                            placeholder="Ingresar Código"
                                        />
                                        <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip">Validar Codigo Cupón</Tooltip>}>
                                            <Button variant="warning" onClick={aplicarDescuento}><Icon.Percent /><Icon.TagFill /></Button>
                                        </OverlayTrigger>
                                    </InputGroup>
                                    {cupon.descuentoAplicado ? <b className='text-success'>{cupon.mensajeDescuento}</b> : <b className='text-danger'>{cupon.mensajeDescuento}</b>}
                                </Col>
                                <Col xs={7}>
                                    {cupon.descuentoAplicado && <b>Descuento:</b>}
                                </Col>
                                <Col xs={5}>
                                    {cupon.descuentoAplicado && <b>%{cupon.descuentoCupon}</b>}
                                </Col>
                            </Row>
                        }
                        <Row className="mb-4 rowColumnRigth">

                        </Row>
                        <Row className="mb-4 rowColumnRigth">
                            <Col xs={7}>
                                <p>Subtotal</p>
                                <p>Impuestos</p>
                                <p>Total Pedido</p>
                                <p>Transporte</p>
                                <p>TOTAL A PAGAR</p>
                            </Col>
                            <Col xs={5}>
                                <p>{formatoPesosColombianos.format(subtotal)}</p>
                                <p>{formatoPesosColombianos.format(impuesto)}</p>
                                <p>{formatoPesosColombianos.format(subtotal + impuesto)}</p>
                                <p>{formatoPesosColombianos.format(transporte)}</p>
                                <p>{formatoPesosColombianos.format(subtotal + impuesto + Math.round(transporte))}</p>
                            </Col>
                        </Row>
                        <Row className="mb-4 rowColumnRigth">
                            <Col>
                                <h4>OBSERVACIONES</h4>
                                <textarea className='textObservaciones' onChange={handleObservacion} maxLength={"1000"}></textarea>
                            </Col>
                        </Row>
                        <Row className="mb-4 rowColumnRigth">
                            <Button style={{ 'display': permisoCotizacion }} id='btnCotizacion' onClick={() => handleCotizacion()}>Cotización</Button>
                            <div id='click'></div>
                        </Row>
                        <Row className="mb-4 rowColumnRigth" style={{ display: permisoCliente }}>
                            <Col sm={1}>
                                <Form.Check onChange={changeAceptacion}
                                    name="terminos_condiciones"
                                    value="Acepto"
                                    className="mb-3"
                                    type="checkbox"
                                    id={`default-checkbox`}
                                />
                            </Col>
                            <Form.Label column sm={8}>
                                <a target={'_new'} href={'./terminosCondiciones'}>Términos y condiciones</a>
                            </Form.Label>
                        </Row>

                        <Row>

                            <Button onClick={() => user.tipo_usuario === 'temporal' ? setShow(true) : queryPedido()} id='btnPedido'>Hacer Pedido</Button>

                            <Modal
                                size="lg"
                                show={show}
                                onHide={() => setShow(false)}
                                backdrop="static"
                                keyboard={false}
                                centered
                                aria-labelledby="example-modal-sizes-title-lg"
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title>Registro Cliente</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                        <Row>
                                            <Col xs={12} md={7} style={{ border: '1px solid' }}>
                                                <br />
                                                <Form.Group as={Row}>
                                                    <Col sm={4}>
                                                        <Form.Label>Tipo-Documento<small className="text-danger">*</small></Form.Label>
                                                    </Col>
                                                    <Col sm={8}>
                                                        <select name='tipo_documento' className="form-control" required onChange={changeFormulario}>
                                                            <option></option>
                                                            <option value={'CC'}>CC - Cédula de ciudadanía</option>
                                                            <option value={'CE'}>CE - Cédula de extranjería</option>
                                                            <option value={'TI'}>TI - Tarjeta de identidad</option>
                                                            <option value={'NIT'}>NIT - Número de Identificación Tributaria</option>
                                                            <option value={'RUT'}>RUT - Registro único tributario</option>
                                                        </select>
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row}>
                                                    <Col sm={4}>
                                                        <Form.Label>Identificación<small className="text-danger">*</small></Form.Label>
                                                    </Col>
                                                    <Col sm={8}>
                                                        <Form.Control type="text" id="numero_identificacion" name="numero_identificacion" pattern={patternIdentificacion.hard} value={objFormulario.numero_identificacion} onChange={changeIdentificacion} placeholder="Identificación" required autoComplete="off" />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row}>
                                                    <Col sm={4}>
                                                        <Form.Label>{patternIdentificacion.lastname === '' ? 'Nombres' : 'Razón Social'}<small className="text-danger">*</small>
                                                            <OverlayTrigger placement="top" overlay={<Tooltip id="button-tooltip">{patternIdentificacion.tooltipName}</Tooltip>}>
                                                                <Button variant="outline-default"><Icon.InfoCircle /></Button>
                                                            </OverlayTrigger>
                                                        </Form.Label>
                                                    </Col>
                                                    <Col sm={8}>
                                                        <Form.Control maxLength={40} type="text" name="nombre" pattern={patternIdentificacion.name} value={objFormulario.nombre} onChange={changeNombre} placeholder={patternIdentificacion.lastname === '' ? 'Nombres' : 'Razón Social'} required autoComplete="off" />
                                                        {mensajeValidacionNombre !== "" && <small className="text-danger">{mensajeValidacionNombre}</small>}
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} className={patternIdentificacion.lastname}>
                                                    <Col sm={4}>
                                                        <Form.Label>Apellidos<small className="text-danger">*</small></Form.Label>
                                                        <OverlayTrigger placement="top" overlay={<Tooltip id="button-tooltip">{patternIdentificacion.tooltipName}</Tooltip>}>
                                                            <Button variant="outline-default"><Icon.InfoCircle /></Button>
                                                        </OverlayTrigger>
                                                    </Col>
                                                    <Col sm={8}>
                                                        <Form.Control maxLength={40} type="text" name="apellido_1" pattern={patternIdentificacion.name} value={objFormulario.apellido_1} onChange={changeNombre} placeholder="Apellidos" required={patternIdentificacion.lastname === 'ocultar' ? false : true} autoComplete="off" />
                                                        {mensajeValidacionApellido !== "" && <small className="text-danger">{mensajeValidacionApellido}</small>}
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row}>
                                                    <Col sm={4}><Form.Label>Departamento<small className="text-danger">*</small></Form.Label></Col>
                                                    <Col sm={8}>
                                                        <select name="departamento" className="form-control" required onChange={changeDepartamentos} >
                                                            {
                                                                arrayDepartamentos.map((dpto) => {
                                                                    return (
                                                                        <option key={dpto.id} value={dpto.id}>
                                                                            {dpto.name}
                                                                        </option>
                                                                    );
                                                                })
                                                            }
                                                        </select>
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row}>
                                                    <Col sm={4}>
                                                        <Form.Label>Ciudad<small className="text-danger">*</small></Form.Label>
                                                    </Col>
                                                    <Col sm={8}>
                                                        <select name="ciudad" className="form-control" required onChange={changeCiudades} >
                                                            {
                                                                arrayCiudades.map((dpto) => {
                                                                    return (
                                                                        <option key={dpto.id} value={dpto.id}>
                                                                            {dpto.name}
                                                                        </option>
                                                                    );
                                                                }
                                                                )}
                                                        </select>
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row}>
                                                    <Col sm={4}><Form.Label>Barrio</Form.Label></Col>
                                                    <Col sm={8}><Form.Control type="text" name="barrio" placeholder="Barrio" onChange={changeFormulario} autoComplete="off" /></Col>
                                                </Form.Group>
                                                <Form.Group as={Row}>
                                                    <Col sm={4}>
                                                        <Form.Label>Dirección<small className="text-danger">*</small>
                                                            <OverlayTrigger placement="top" overlay={<Tooltip id="button-tooltip">campo solo permite números, letras mayusculas, letras minusculas</Tooltip>}>
                                                                <Button variant="outline-default"><Icon.InfoCircle /></Button>
                                                            </OverlayTrigger>
                                                        </Form.Label>
                                                    </Col>
                                                    <Col sm={8}>
                                                        <Form.Control type="text" name="direccion" placeholder="Dirección" required value={objFormulario.direccion} onChange={changeDireccion} pattern="^[a-zA-Z0-9 ]+$" autoComplete="off" />
                                                        {mensajeValidacionDireccion !== "" && <small className="text-danger">{mensajeValidacionDireccion}</small>}
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row}>
                                                    <Col sm={4}>
                                                        <Form.Label>Teléfono<small className="text-danger">*</small>
                                                            <OverlayTrigger placement="top" overlay={<Tooltip id="button-tooltip">campo solo permite números</Tooltip>}>
                                                                <Button variant="outline-default"><Icon.InfoCircle /></Button>
                                                            </OverlayTrigger>
                                                        </Form.Label>
                                                    </Col>
                                                    <Col sm={8}>
                                                        <Form.Control type="number" name="telefono" id="telefono" placeholder="Teléfono" required onChange={validacionTelefono} autoComplete="off" />
                                                        {mensajeValidacionTelefono !== "" && <small className="text-danger">{mensajeValidacionTelefono}</small>}
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row}>
                                                    <Col sm={4}><Form.Label>Correo<small className="text-danger">*</small></Form.Label></Col>
                                                    <Col sm={8}>
                                                        <Form.Control type="email" id="email" name="email" placeholder="Correo" onChange={changeEmail} autoComplete="off" required />
                                                        {mensajeValidacionEmail !== "" && <small className="text-danger">{mensajeValidacionEmail}</small>}
                                                    </Col>

                                                </Form.Group>
                                                <Form.Group as={Row}>
                                                    <Col sm={4}><Form.Label>Contraseña</Form.Label></Col>
                                                    <Col sm={8}>
                                                        <InputGroup>
                                                            <Form.Control type="password" name="password_cliente" id="password_cliente" placeholder="Contraseña" onChange={blurPassword} autoComplete="off" />
                                                            <Button variant="warning" onClick={mostrarPassword}><Icon.Eye id="iconoMostrar" style={{ display: "none" }} /><Icon.EyeSlash id="iconoOcultar" /></Button>
                                                        </InputGroup>
                                                        {mensajeValidacionPassword !== "" && <small className="text-danger">{mensajeValidacionPassword}</small>}
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row}>
                                                    <Col sm={6}>
                                                        <Form.Label column>
                                                            <a target={'_new'} href={'./terminosCondiciones'}>Términos y condiciones<small className="text-danger">*</small></a>
                                                        </Form.Label>
                                                    </Col>
                                                    <Col>
                                                        <Form.Check style={{ textAlign: 'left' }} onChange={changeFormulario}
                                                            name="terminos_condiciones"
                                                            value="Acepto"
                                                            className="mb-3"
                                                            type="checkbox"
                                                            id={`default-checkbox`}
                                                            required
                                                        />
                                                    </Col>
                                                    {mensajeValidacionTerminos !== "" && <small className="text-danger">{mensajeValidacionTerminos}</small>}
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} md={5} style={{ border: '1px solid' }}>

                                                <div style={{ marginTop: '5%', background: '#eaeaea' }}>
                                                    <h3><strong>INFORMACIÓN DEL PEDIDO</strong></h3>
                                                    <Row>
                                                        <Col style={{ textAlign: 'right' }} xs={6}><Form.Label column><strong>Subtotal </strong></Form.Label></Col>
                                                        <Col xs={2}><Form.Label column>{formatoPesosColombianos.format(subtotal)}</Form.Label></Col>
                                                    </Row>
                                                    <Row>
                                                        <Col style={{ textAlign: 'right' }} xs={6}><Form.Label column><strong>Impuestos </strong></Form.Label></Col>
                                                        <Col xs={2}><Form.Label column>{formatoPesosColombianos.format(impuesto)}</Form.Label></Col>
                                                    </Row>
                                                    <Row>
                                                        <Col style={{ textAlign: 'right' }} xs={6}><Form.Label column><strong>Transporte </strong></Form.Label></Col>
                                                        <Col xs={2}><Form.Label column>{formatoPesosColombianos.format(transporte)}</Form.Label></Col>
                                                    </Row>
                                                    <Row>
                                                        <Col style={{ textAlign: 'right' }} xs={6}><Form.Label column><strong>Total Pagar </strong></Form.Label></Col>
                                                        <Col xs={2}><Form.Label column>{formatoPesosColombianos.format(subtotal + impuesto + Math.round(transporte))}</Form.Label></Col>
                                                    </Row>
                                                    <br />
                                                    <Row style={{ textAlign: 'center' }}>
                                                        <Form.Label><strong>Medio de Pago: </strong>{singleMetodoPago}</Form.Label>
                                                        <Form.Label><strong>Medio de entrega:</strong> {singleMedioEntrega}</Form.Label>
                                                        <Form.Label><strong>Observación: </strong>{observacion}</Form.Label>
                                                    </Row>
                                                    <br />
                                                    <Row style={{ textAlign: 'center' }}>
                                                        <Col>
                                                            <Button type='submit' variant="warning" id="btnPedidoCliente" disabled={disabledButton}> {disabledButton ? 'Cargando...' : 'Aceptar'}</Button>
                                                        </Col>
                                                    </Row>
                                                    <br />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Modal.Body>
                            </Modal>
                        </Row>

                    </Col>
                </Row>
            </Container>
            <Footer />
        </>
    );
}

export { Carrito };   