import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import {Card, Button, Row, Col, Container, Modal, Form} from 'react-bootstrap';
import Cookie from 'universal-cookie';
import axios from 'axios';
import ReactDOM from 'react-dom/client';
import {CartCheckFill} from 'react-bootstrap-icons';

function CardProducto (props) {
    const { propiedades } = props;
    let cookies = new Cookie();

    const [show, setShow] = useState(false);
    const [showModalResult, setShowModalResult] = useState(false);
    const [tituloMensaje, setTituloMensaje] = useState(null);
    const [validacionAgregarCarrito, setValidacionAgregarCarrito] = useState(false);
    let [objItem, setObjItem] = useState(null);
    const [cantidad, setCantidad] = useState(1);
    const [mensajeResult, setMensajeResult] = useState('');

    const formatoPesosColombianos = new Intl.NumberFormat('es-CO', {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 0
    });

    const formatoPorcentaje = (num) =>{
        return Number(num/100).toLocaleString(undefined,{style: 'percent', minimumFractionDigits:2});
    }

    const changeCantidad = async (e) => {
        let { value } = e.target;
        setCantidad(value);
    }

    let validacionDescuento = "divDescuento ocultar";

    if(propiedades.descuento > 0){
        validacionDescuento = "divDescuento";
    }

    let validacionNuevo = "divNuevo ocultar";

    if(propiedades.item_nuevo === "SI"){
        validacionNuevo = "divNuevo";
    }

    const validacionAgregarProducto = async () => {
        let token = cookies.get('accesToken');
        try {
            const url = process.env.REACT_APP_SERVER + '/requireAuth/routes';
            const response = await axios.get(url, {headers: {
                "Authorization": `Bearer ${token}`
            }});
            const data = response.data;
            if(data.validation === 1){
                let objItemAux = {
                    item:propiedades.item_pedido,
                    descripcion:propiedades.descripcion_item,
                    um:propiedades.um,
                    precio:propiedades.precio_antes,
                    descuento_original: propiedades.descuento,
                    descuento:propiedades.descuento,
                    valor_final:propiedades.precio_despues
                };
                setCantidad(1);
                setTituloMensaje("AGREGAR PRODUCTO");
                setObjItem(objItemAux);
                setValidacionAgregarCarrito(true);
            }
        }catch (err) {
            console.log(err);
            if(err.response.status === 403 ||
                err.response.status === 401){
                    setTituloMensaje("Información");
            }
        }
        setShow(true);
    }

    const registrarProductoCarrito = async () => {
        let token = cookies.get('accesToken');
        const user = cookies.get('user');
        const urlCarrito = process.env.REACT_APP_SERVER + '/api/v1/shoppingCart/list';
        const responseCarrito = await axios.get(urlCarrito, {headers: {
            "Authorization": `Bearer ${token}`
        }});
        const dataCarrito = responseCarrito.data[0];        
        cookies.set('carrito', (dataCarrito.length)+1);      
        
        if(cantidad % 1 !== 0){
            alert('La cantidad debe ser un número entero')
        }else if(cantidad <= 0){
            alert('La cantidad debe ser mayor a 0')
        }else{
            try {
                const urlDescuentosVolumen = process.env.REACT_APP_SERVER + '/api/v1/discountsVolume'
                const bodyDescuentosVolumen = {item: propiedades.item_pedido, cantidad: cantidad, descuento: propiedades.descuento}
                const { data } = await axios.post(urlDescuentosVolumen, bodyDescuentosVolumen, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
    
                objItem = {
                    ...objItem,
                    descuento: data.descuentoFinal
                }
    
                const urlRegistrar = process.env.REACT_APP_SERVER + '/api/v1/shoppingCart/register';
                const body = {cantidad, objItem};
                const responseRegistrar = await axios.post(urlRegistrar, body, {headers: {
                    "Authorization": `Bearer ${token}`
                }});
                const dataRegistrar = responseRegistrar.data;
                setMensajeResult(dataRegistrar["message"]);
                setShow(false);
                setShowModalResult(true);
                cookies.set('accesToken', dataRegistrar["accessToken"], {path: '/'})
            }catch (err) {
                console.log(err);
                if(err.response.status === 403 ||
                    err.response.status === 401){
                        setTituloMensaje("Información");
                }
            }
            if(user.tipo_usuario === 'temporal'){           

                const root = ReactDOM.createRoot(
                    document.getElementById('numberCarTemporal')
                  );
                  const element = <span  className="badge badge-dark">{cookies.get('carrito')}</span>
                    root.render(element);
                }else{
                const root = ReactDOM.createRoot(
                    document.getElementById('numberCar')
                  );
                  const element = <span  className="badge badge-dark">{cookies.get('carrito')}</span>
                    root.render(element);
    
            }
        }
    }

    const cerrarModal = () =>{
        setShowModalResult(false);
    }

    return(
        <>
            <Card className="mx-auto cardProducto" id='cardLanding' > 
                <div className="divInfoAdicionalCard">
                    <div className={validacionNuevo} >NUEVO</div>
                    <div className={validacionDescuento} >{formatoPorcentaje(propiedades.descuento)}</div>
                </div>
                <Link to={`/productSingle/${propiedades.item_pedido}`} state={{item_pedido: propiedades.item_pedido}}>
                    <Card.Img alt="240x240" loading='lazy' fallback={<p>Cargando</p>} variant="top" src={process.env.REACT_APP_SERVER_IMAGE + 'items/' + propiedades.item_pedido + '_0_240x240.webp'} className="imgProducto" />
                </Link>
                <Card.Body >
                    <Card.Title className='card-title-descripcion' style={{ lineHeight: '1'}}>
                        <Link className={'descripcion'} aria-label="Descripción detallada del producto" to={`/productSingle/${propiedades.item_pedido}`} state={{item_pedido: propiedades.item_pedido}}>{propiedades.descripcion_item}</Link>
                    </Card.Title>
                    <Card.Text>
                        <small>Ref: {propiedades.referencia}</small>
                        <br></br>
                        <small>Codigo: {propiedades.item_pedido}</small>
                        <br></br>
                        <small>UM: {propiedades.um}</small>
                        <br></br>
                        <small>MARCA: {propiedades.marca}</small>
                    </Card.Text>
                        {   
                            propiedades.precio_antes === propiedades.precio_despues
                            ?
                            <Row className="filaPrecio">
                                <Col xs={6} sm={12} className="precioSInDescuento mt-3">
                                    <Row>
                                        <Col xs={12}>
                                            <b className="colorTextoLanding">{formatoPesosColombianos.format(propiedades.precio_despues)}</b>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            :
                            <Row className="filaPrecio">
                                <Col xs={6} className="centrarVertical precioAntes">
                                    <small className="textoTachado">Antes: {formatoPesosColombianos.format(propiedades.precio_antes)}</small>
                                </Col>
                                <Col xs={6} className="precioDespues">
                                    <Row>
                                        <Col xs={12} className="colTexto">
                                            <b className="colorTextoLanding">AHORA</b>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} className="colTexto">
                                            <b className="colorTextoLanding">{formatoPesosColombianos.format(propiedades.precio_despues)}</b>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        }
                        <Card.Text className='conIva'><small>Con iva incluido. COP</small></Card.Text>
                        <Button className='btnLanding' variant="outline-warning" onClick={validacionAgregarProducto}>Agregar al carrito <CartCheckFill/></Button>

                </Card.Body>
            </Card>

            <Modal
            show={show}
            backdrop="static"
            keyboard={false}
            onHide={() => setShow(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {tituloMensaje}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        validacionAgregarCarrito
                        ?
                            <Row>
                                <Col sm={12}>
                                    <b>Item:</b> {objItem.descripcion}
                                    <br></br>
                                    <b>Código:</b> {objItem.item}
                                    <br></br>
                                    Ingrese la cantidad que desea comprar de este producto
                                    <br></br>
                                    <Form.Group as={Row} className="mb-3">
                                        <Form.Label column sm={4}>
                                            <b>Cantidad</b>
                                        </Form.Label>
                                        <Col sm={8}>
                                            <Form.Control type="number" name="cantidad" min="1" value={cantidad} onChange={changeCantidad}/>
                                        </Col>
                                    </Form.Group>
                                    <div className="d-grid gap-2">
                                        <Button variant="outline-warning btnAgregar" type="submit" onClick={registrarProductoCarrito}>Agregar al carrito</Button>
                                        <Button variant="outline-secondary" type="submit" onClick={() => setShow(false)}>Cancelar</Button>
                                    </div>
                                </Col>
                            </Row>
                        :
                            <Row>
                                <Col sm={12}>
                                    Para agregar productos al carrito de compra se debe de iniciar sesión
                                    <br></br>
                                    <Link to='/inicioSesion' className='btn btn-outline-warning'>Iniciar sesión</Link>
                                </Col>
                            </Row> 
                    }
                </Modal.Body>
            </Modal>

            <Modal
                show={showModalResult}
                backdrop="static"
                keyboard={false}
                onHide={() => setShowModalResult(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Información</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {mensajeResult}
                    <div className="d-grid gap-2">
                        <Button variant="outline-warning btnAgregar" type="submit" onClick={cerrarModal}>Continuar Comprando</Button>
                        <Link to='/carrito' className='btn btn-outline-secondary'>Ir al Carrito</Link>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export {CardProducto};