import React, { Fragment, useRef } from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import * as Icon from 'react-bootstrap-icons';
import { Form, Row, Col, Figure, InputGroup, Button, Stack, Card } from "react-bootstrap";
import { CardProductoPrice } from '../../components/CardProductoPrice';

function PlantillaB2C1({
    imagenesData,
    dataPrecio,
    dataPrecioAntes,
    dataDescuento,
    dataUnidadMedida,
    usosData,
    descripcionUsos,
    tipoClientePagina,
    cantidad,
    stock,
    umStock,
    itemMetros,
    handleCantidad,
    handleUnidadMedida,
    queryAgregarCarrito,
    abrirModalDisponibilidad,
    modal,
    titulo,
    itemsRecommendation,
    imagenBanner,
    imagenBanner3,
    disabledButton }) {

    const sliderRef = useRef(null);
    const imagenPublicidad = process.env.REACT_APP_SERVER_IMAGE + 'estaticas/';

    const formatoPorcentaje = (num) => {
        return Number(num / 100).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2 });
    }

    const formatoPesosColombianos = new Intl.NumberFormat('es-CO', {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 0
    });

    let settingsBanners = {
        autoplay: true,
        speed: 500,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    let settings2 = {
        dots: false,
        infinite: true,
        speed: 500,
        autoplay: true,
        slidesToShow: 2,
        slidesToScroll: 2,
        responsive: [
            {
                breakpoint: 1440,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <>
            <Row xs={1} sm={2}>
                <Col>
                    <Stack>
                        <div style={{ position: 'relative' }}>
                            <Slider ref={sliderRef} {...settingsBanners} >
                                {
                                    imagenesData.length > 0
                                        ?
                                        imagenesData.map((fila, id) =>
                                            <Figure key={id} id={id} >
                                                <Figure.Image
                                                    width={500}
                                                    height={800}
                                                    alt="imagenPrudct"
                                                    src={fila.variante}
                                                />
                                            </Figure>
                                        )
                                        :
                                        <label></label>
                                }
                            </Slider>
                            {
                                dataDescuento === 0.00 ? '' : <Button className='btnAdicionar' variant="danger" style={{ position: 'absolute', top: 0, right: 0 }}><b>{formatoPorcentaje(dataDescuento)}</b></Button>
                            }
                        </div>
                        <div>
                            {
                                imagenesData.map((item, i) =>
                                    <Figure key={i}  >
                                        <Figure.Image
                                            width={75}
                                            height={75}
                                            alt="variant"
                                            onClick={() => { sliderRef.current.slickGoTo(i) }}
                                            src={item.thumbnail}
                                        />
                                    </Figure>
                                )
                            }
                        </div>
                    </Stack>
                </Col>
                <Col style={{ textAlign: 'center' }}>
                    <h1>{usosData.descripcion_alternativa}</h1>
                    <h6><b>Referencia: </b>{usosData.referencia} <b>Codigo: </b>{usosData.item} <b>Marca:</b> {usosData.marca}</h6>
                    <hr />
                    <div>
                        {
                            usosData.caracteristicas !== null ?
                                <h5>✅<b>{usosData.caracteristicas}</b></h5>
                                :
                                ''
                        }
                    </div>
                    {
                        dataPrecioAntes === 0
                            ?
                            <div style={{ textAlign: 'center' }}>
                                <div className='text_precio'>
                                    <h1 className='text-danger display-1'>
                                        <b>{formatoPesosColombianos.format(dataPrecio)}</b>
                                    </h1>
                                    <small> IVA incluido</small>
                                </div>
                                {
                                    itemMetros
                                        &&
                                        <div className='div_precio_metros'>
                                            <label ><p className="text_precio">{formatoPesosColombianos.format(dataPrecio / usosData.metros)} METRO CUADRADO</p></label>
                                        </div>
                                }
                            </div>
                            :
                            <div>
                                <div className='text_precio'>
                                    <h1 className='text-danger display-2'>
                                        <b>{formatoPesosColombianos.format(dataPrecio)}</b>
                                    </h1>
                                    <small> IVA incluido</small>
                                </div>
                                <div className='textoTachado'>
                                    <h4><b>Antes: {formatoPesosColombianos.format(dataPrecioAntes)}</b></h4>
                                </div>
                                {
                                    itemMetros
                                        &&
                                        <div className='div_precio_metros'>
                                            <label ><p className="text_precio">{formatoPesosColombianos.format(dataPrecio / usosData.metros)} METRO CUADRADO</p></label>
                                        </div>
                                }
                            </div>
                    }
                    {
                        ((usosData.precio_solo_pagina === tipoClientePagina) || (usosData.precio_solo_pagina === 'TODOS'))
                            &&
                            <Col xs={12} style={{ color: "green" }}>
                                <Icon.Globe /><strong> Precio solo página</strong>
                            </Col>
                    }
                    <div className='div_caracteristicas'>
                        {
                            stock === 0
                                ?
                                <label><b>Disponibilidad: <span style={{ color: 'red' }}>VALIDAR DISPONIBILIDAD</span></b></label>
                                :
                                <div>
                                    <label><b>Disponibilidad:</b> {stock} {umStock} <Button variant="outline-secondary" size="sm" onClick={abrirModalDisponibilidad}><Icon.InfoCircleFill /> Ver Stock de Tiendas</Button></label>
                                    <br></br>
                                    <h4>🔥<b>Compralo ahora,este producto se vende rápido</b></h4>
                                </div>
                        }
                    </div>
                    <InputGroup className="mb-3">
                        <Form.Control
                            type="number"
                            aria-label="Example text with button addon"
                            aria-describedby="basic-addon1"
                            min="1"
                            value={cantidad}
                            onChange={handleCantidad}
                        />
                        <Form.Select onChange={handleUnidadMedida} size="sm">
                            {
                                dataUnidadMedida.map((item, i) =>
                                    <Fragment key={i}>
                                        <option value={item.unidad_de_medida}>{item.unidad_de_medida}</option>
                                    </Fragment>
                                )
                            }
                        </Form.Select>
                        <Button className='btnAdicionar' variant="outline-warning" id="button-addon1"  disabled={disabledButton} onClick={() => queryAgregarCarrito()}>
                            <Icon.CartCheckFill /><strong> Agregar al carrito</strong>
                        </Button>
                        {modal}
                    </InputGroup>
                    {
                        imagenBanner3.map((fila, id) =>
                            <div key={id} >
                                <a href={fila.telefono} target={'_new'}>
                                    <img src={imagenPublicidad + fila.pagina} alt="bannerPrincipal" style={{ maxHeight: 'max-content' }} id='Banner' />
                                </a>
                            </div>
                        )
                    }
                </Col>
            </Row>
            <hr />
            <Row>
                <Col md={6}>
                    <h4 className='title'>Información Adicional</h4>
                    <div style={{ textAlign: 'justify !important' }}>
                        {
                            descripcionUsos[0]
                                ?
                                (
                                    descripcionUsos.map((html, index) => (
                                        <div key={index} dangerouslySetInnerHTML={{ __html: html }} />
                                    ))
                                )
                                :
                                (usosData.descripcion_producto)
                        }
                    </div>
                </Col>
                <Col md={6}>
                    <h3>{titulo}</h3>
                    <Slider {...settings2}>
                        {
                            itemsRecommendation.map((item, i) =>
                                <Fragment key={i}>
                                    <CardProductoPrice propiedades={item} />
                                </Fragment>
                            )
                        }
                    </Slider>
                    <br></br>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className='border border-warning pb-3' style={{ textAlign: 'center' }}>
                        <h1 className='bg-warning pb-3 display-2'><b><Icon.ShieldCheck /> ¿Este producto tiene garantía?</b></h1>
                        <h1><b style={{ color: '#198754' }}>Todos nuestros productos son originales</b> cuentan con garantía, además de <b style={{ color: '#198754' }}>COMPRA PROTEGIDA.</b></h1>
                        <br></br>
                        <h1>Recibirás una confirmación de pedido y detalles de envío en tu correo electrónico.</h1>
                        <h1>Recuerda que realizamos envíos a nivel nacional y tenemos diferentes medios de pago</h1>
                        <br></br>
                        <Link to='/registrarUsuario' state={{ page: '/' }}><Button variant="dark"><h1><Icon.BoxArrowInRight /> Regístrate para mantenerte al tanto de futuros descuentos</h1></Button></Link>
                    </div>
                    <br></br>
                    {
                        imagenBanner.map((fila, id) =>
                            <div key={id} style={{ width: '100%', maxWidth: '100%', height: '30%', textAlign: 'center' }}>
                                <a href={fila.telefono} target={'_new'}>
                                    <img src={imagenPublicidad + fila.pagina} alt="bannerPrincipal" />
                                </a>
                            </div>
                        )
                    }
                </Col>
            </Row>
            <a className='btn-wsp' target={"_new"} href={`https://wa.me/${usosData.numero_whatsApp}?text=Hola, me encuentro en la página web de la FERRETERIA MASTER y quisiera conocer más información sobre el producto ${usosData.descripcion_alternativa}, ítem ${usosData.item} con referencia ${usosData.referencia}`} aria-label="Número de whatsApp de nuestros asesores">
                <Card.Img className='btnWsp1' alt="75x75" src={process.env.REACT_APP_SERVER_IMAGE + 'estaticas/whatsapp1.webp'} />
            </a>
        </>
    );
}

export { PlantillaB2C1 };