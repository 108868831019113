import React, { useEffect, useState, Fragment } from 'react';
import axios from 'axios';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import '../sources/styles/stylesProductSingle.css';
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';
import { CardProductoPrice } from '../components/CardProductoPrice';
import { PlantillaB2C1 } from './plantillas/PlantillaB2C1';
import Slider from "react-slick";
import * as Icon from 'react-bootstrap-icons';
import {Container, ListGroup, Form, Row, Col, Figure, InputGroup, Button, Modal, Table, Stack, Card, Alert, Spinner } from "react-bootstrap";

import ReactDOM from 'react-dom/client';

function ProductSingle() {
    let navigate = useNavigate();
    const Location = useLocation();
    const params = useParams();
    let item_pedido = '';
    let tipoCliente = params.item_pedido.split('-')
    const [tipoClientePagina, setTipoClientePagina] = useState('');

    const queryTokenNuevo = async (item, tipo_cliente) => {
        const cookies = new Cookies();
        try {
            const ip = await consultarIp();
            const url = process.env.REACT_APP_SERVER + '/api/v1/token/productSingle';
            const body = { item_pedido: item, tipo_cliente: tipo_cliente, ipUsuario: ip };
            const { data } = await axios.post(url, body);
            cookies.set('accesToken', data.accessToken, { path: '/' })
        } catch (error) {
            console.log('Error en la consulta de token productSingle: ' + error.message);
        }
    }

    const consultarIp = async () => {
        let ip = ''
        try {
            const url = 'https://www.cloudflare.com/cdn-cgi/trace';
            const { data } = await axios.get(url);
            ip = data.split('\n')
            ip = ip[2].split('=')
            ip = ip[1];
        } catch (err) {
            const url2 = 'https://geolocation-db.com/json/';
            const { data } = await axios.get(url2);
            ip = data.IPv4;
        }
        return ip
    }

    if (Location.state === null) {
        if (tipoCliente[1] === 'B2B' || tipoCliente[1] === 'DETAL') {
            navigate('/inicioSesion', { state: { page: '/productSingle', item_pedido: tipoCliente[0] } });
        } else if (tipoCliente[1] === 'B2C') {
            item_pedido = tipoCliente[0]
            queryTokenNuevo(tipoCliente[0], tipoCliente[1]);
        } else {
            item_pedido = tipoCliente[0]
        }
    } else {
        item_pedido = Location.state.item_pedido
    }

    let [objItem, setObjItem] = useState({
        item: item_pedido,
        um: '',
        cantidad: 1,
        precio: '',
        descuento_original: '',
        descuento: '',
        valor_final: ''
    });

    let [discountShow, setDiscountShow] = useState({
        descuento: '0%',
        permiso: 'none'
    })

    const [usosData, setUsosData] = useState([]);
    const [descripcionUsos , setDescripcionUsos]= useState([]);
    const [anexosData, setAnexosData] = useState([]);
    const [fichaTecnicaData, setFichaTecnicaData] = useState([]);
    const [imagenesData, setImagenesData] = useState([]);
    const [itemsRecommendation, setItemsRecommendation] = useState([]);
    const [dataUnidadMedida, setDataUnidadMedida] = useState([]);
    const [dataPrecio, setDataPrecio] = useState([]);
    const [dataPrecioAntes, setDataPrecioAntes] = useState(0);
    const [dataDescuento, setDataDescuento] = useState([]);
    const [titulo, setTitulo] = useState('');
    const [validacionUnidadMedida, setValidacionUnidadMedida] = useState(false);
    const [imagenProductVariant, setImagenProductVariant] = useState(process.env.REACT_APP_SERVER_IMAGE + 'items/' + item_pedido + '_0_550x550.webp');
    const [show, setShow] = useState(false);
    const [volumenShow, setVolumenShow] = useState([]);
    const [cantidad, setCantidad] = useState(1);
    const [imagenBanner, setImagenBanner] = useState([]);
    const [imagenBanner3, setImagenBanner3] = useState([]);
    const [stock, setStock] = useState(0);
    const [umStock, setUmStock] = useState('');
    const [itemEspecial, setItemEspecial] = useState('0');
    const [plantilla, setPlantilla] = useState(false);
    const [itemMetros, setItemMetros] = useState(false);
    const [slidesToShow, setSlidesToShow] = useState('4');
    const [item, setItem] = useState([]);
    const [showModalDisponibilidad, setShowModalDisponibilidad] = useState(false);
    const [disabledButton, setDisabledButton] = useState(true);
    const arrayCentro = {
        '001':'Buga',
        '002':'Palmira',
        '003':'Tuluá'
    };

    let imagenBanner2 = process.env.REACT_APP_SERVER_IMAGE+'estaticas/baner_principal-small2.webp';
    const formatoPesosColombianos = new Intl.NumberFormat('es-CO', {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 0
    });

    const formatoPorcentaje = (num) => {
        return Number(num / 100).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2 });
    }

    const imagenPublicidad = process.env.REACT_APP_SERVER_IMAGE + 'estaticas/';

    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        autoplay: true,
        slidesToShow: slidesToShow,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    var settingsDescuentosVolumen = {
        dots: true,
        infinite: true,
        speed: 500,
        autoplay: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const handleUnidadMedida = async e => {
        setValidacionUnidadMedida(true);
        try {
            const cookies = new Cookies();
            const accessToken = cookies.get('accesToken')
            const urlPrecios = process.env.REACT_APP_SERVER + '/api/v1/discounts/prices';
            const bodyPrecios = { item: item_pedido, unidad_de_medida: e.target.value }
            const { data } = await axios.post(urlPrecios, bodyPrecios, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });

            if (data.length > 0) {
                let precioFinal = parseInt(data[0].precio * (1 - parseFloat(dataDescuento) / 100));

                setObjItem({
                    ...objItem,
                    valor_final: precioFinal,
                    precio: precioFinal,
                    um: e.target.value
                })
                setDataPrecio(precioFinal);

                if (e.target.value === umStock) {
                    setItemMetros(false);
                } else {
                    if(usosData.metros !== '0.00'){
                        setItemMetros(true);
                    }else{
                        setItemMetros(false);
                    }
                }
            }

        } catch (error) {
            console.log(error)
        }
    }

    const handleCantidad = async e => {
        const cookies = new Cookies();
        const accessToken = cookies.get('accesToken')
        if (e.target.value % 1 === 0) {
            if (e.target.value === '0') {
                alert('La cantidad debe ser diferente de 0');
            } else {
                if (e.target.value < 0) {
                    alert('La cantidad debe ser mayor a 0');
                } else {
                    setCantidad(e.target.value)
                    const url = process.env.REACT_APP_SERVER + '/api/v1/discountsVolume';
                    const body = { item: item_pedido, cantidad: e.target.value, descuento: objItem.descuento, precio: objItem.precio };
                    const { data } = await axios.post(url, body, {
                        headers: {
                            'Authorization': `Bearer ${accessToken}`
                        }
                    });

                    let precioAux = objItem.precio * (1 - data.descuentoFinal / 100)
                    setDataPrecio(Math.round(precioAux))
                    setDataDescuento(data.descuentoFinal);
                    setObjItem({
                        ...objItem,
                        cantidad: e.target.value,
                        valor_final: parseInt(objItem.precio * e.target.value * (1 - parseFloat(data.descuentoFinal) / 100)),
                    });
                }
            }
        } else {
            alert('La cantidad debe de ser un número entero')
        }
    }

    const queryAgregarCarrito = async () => {
        validationRoute();
        const cookies = new Cookies();
        const accessToken = cookies.get('accesToken');
        const user = cookies.get('user');

        if (cantidad !== '') {
            if (!validacionUnidadMedida) {
                objItem = ({
                    ...objItem,
                    um: dataUnidadMedida[0].unidad_de_medida,
                    descuento: dataDescuento,
                    precio: (dataPrecioAntes > 0 ? dataPrecioAntes : dataPrecio)
                });
            } else {
                objItem = ({
                    ...objItem,
                    descuento: dataDescuento,
                    precio: (dataPrecioAntes > 0 ? dataPrecioAntes : dataPrecio)
                });
            }
            try {
                let respuesta = true;
                if(user.lista_de_precios === "001"){
                    const urlCargue = process.env.REACT_APP_SERVER + '/api/v1/customers/consultCargue';
                    const responseCargue = await axios.post(urlCargue, { id_cliente: user.id_cliente, item: objItem.item, cantidad: cantidad });
                    let dataCargue = responseCargue.data;
                    if(dataCargue.respuesta !== ''){
                        respuesta = window.confirm(dataCargue.respuesta);
                    } 
                }
    
                if(respuesta){
                const url = process.env.REACT_APP_SERVER + '/api/v1/shoppingCart/register'
                const body = { objItem, cantidad: objItem.cantidad };
                await axios.post(url, body, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    }
                });

                setShow(true)
                setCantidad(1);
                const urlCarrito = process.env.REACT_APP_SERVER + '/api/v1/shoppingCart/list';
                const responseCarrito = await axios.get(urlCarrito, {
                    headers: {
                        "Authorization": `Bearer ${accessToken}`
                    }
                });
                const dataCarrito = responseCarrito.data[0];
                cookies.set('carrito', (dataCarrito.length));

                if (user.tipo_usuario === 'temporal') {
                    const root = ReactDOM.createRoot(
                        document.getElementById('numberCarTemporal')
                    );
                    const element = <span className="badge badge-dark">{cookies.get('carrito')}</span>
                    root.render(element);
                } else {
                    const root = ReactDOM.createRoot(
                        document.getElementById('numberCar')
                    );
                    const element = <span className="badge badge-dark">{cookies.get('carrito')}</span>
                    root.render(element);
                }
            }
            } catch (error) {
                console.log(error)
            }
        } else {
            alert("La cantidad debe ser diferente de vacio")
        }

    }

    const modal = <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
            <Modal.Title>Se agregó al carrito</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
            <Button id="btnProductSingleAceptar" variant="primary" onClick={() => setShow(false)}>
                Aceptar
            </Button>
        </Modal.Footer>
    </Modal>

    const validationRoute = async () => {

        const cookies = new Cookies();
        const accessToken = cookies.get('accesToken');
        try {
            const url = process.env.REACT_APP_SERVER + "/requireAuth/routes";
            await axios.get(url, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });

        } catch (error) {
            console.log('La validación presentó error')
            console.log(error);
            cookies.remove('accessToken', { path: "/" });
            cookies.remove('username', { path: "/" });
            cookies.remove('id', { path: "/" });
            window.location.href = "/inicioSesion";
        }
    }

    const abrirModalDisponibilidad = async () =>{
        try {
            const url = process.env.REACT_APP_SERVER + '/api/v1/items/ItemExistencias';
            const response = await axios.post(url, { item: item_pedido });
            let data = response.data;
            setItem(data);
        } catch (error) {
            console.log('Error en la consulta de items: ' + error.message);
        }
        setShowModalDisponibilidad(true);
    }

    const cerrarModalDisponibilidad = () =>{
        setShowModalDisponibilidad(false);
    }

    useEffect(() => {
        let valorStock=0;
        const cookies = new Cookies();
        const user = cookies.get('user');
        setDisabledButton(true);
        setCantidad(1);
        setObjItem({
            ...objItem,
            cantidad: 1
        });

        setImagenProductVariant(process.env.REACT_APP_SERVER_IMAGE + 'items/' + item_pedido + '_0_550x550.webp')
        const queryUsos = async () => {
            try {
                const cookies = new Cookies();
                const accessToken = cookies.get('accesToken');
                if (accessToken === undefined) {
                    window.location.reload(true);
                }

                const bodyUnidad = { item: item_pedido }
                const urlUnidad = process.env.REACT_APP_SERVER + '/api/v1/discounts/prices/und';
                const responseUnidad = await axios.post(urlUnidad, bodyUnidad, {
                    headers: {
                        "Authorization": `Bearer ${accessToken}`
                    }
                });

                setDataUnidadMedida(responseUnidad.data);

                const url = process.env.REACT_APP_SERVER + `/api/V1/productSingle/${item_pedido}`;
                const response = await axios.get(url);
                setUsosData(response.data[0]);
                setUmStock(response.data[0].um_inventario);
                const parser = new DOMParser();
                const htmlUsos = parser.parseFromString(response.data[0].descripcion_producto, 'text/html');
                const childNodesUsos = htmlUsos.body.childNodes;
                const htmlNodes = Array.from(childNodesUsos).map(node => node.outerHTML);
                setDescripcionUsos(htmlNodes);
                if(responseUnidad.data.length > 0){
                    if (responseUnidad.data[0].unidad_de_medida === response.data[0].um_inventario) {
                        setItemMetros(false);
                    } else {
                        if(response.data[0].metros !== '0.00'){
                            setItemMetros(true);
                        }else{
                            setItemMetros(false);
                        }
                    }
                }

                setPlantilla('0');
                if (user.lista_de_precios === '014' || user.lista_de_precios === undefined) {
                    setItemEspecial(response.data[0].especial);
                    setPlantilla(response.data[0].plantillaB2C);
                    if(response.data[0].b2c === 'NO'){
                        setUsosData(undefined);
                    }
                }else if (user.lista_de_precios === '001'){
                    setPlantilla(response.data[0].plantillaB2B);
                        if(response.data[0].b2b === 'NO'){
                            setUsosData(undefined);
                        }
                }else if  (user.lista_de_precios === '010'){
                    setPlantilla(response.data[0].plantillaDETAL);
                    if(response.data[0].detal === 'NO'){
                        setUsosData(undefined);
                    }
                }
            } catch (error) {
                console.log(error);
            }
        }

        const queryAnexos = async () => {
            try {
                const url = process.env.REACT_APP_SERVER + `/api/consulta/tabla_anexos/${item_pedido}`;
                const response = await axios.get(url);
                setAnexosData(response.data);
            } catch (error) {
                console.log(error);
            }
        }

        const queryFichaTecnica = async () => {
            try {
                const url = process.env.REACT_APP_SERVER + `/api/consulta/tabla_ficha_tecnica/${item_pedido}`;
                const response = await axios.get(url);
                setFichaTecnicaData(response.data);
            } catch (error) {
                console.log(error);
            }
        }

        const queryImagenes = async () => {
            try {
                const url = process.env.REACT_APP_SERVER + `/api/consulta/tabla_imagenes/` + item_pedido;
                const resultImagenes = await axios.get(url);
                if(resultImagenes.data.length > 0){
                    let  arrayVariantesAux = [];
                    resultImagenes.data.forEach(item => {
                        arrayVariantesAux.push({
                            variante: process.env.REACT_APP_SERVER_IMAGE + 'items/' + item_pedido + '_' + item.variante + '_550x550.webp',
                            thumbnail: process.env.REACT_APP_SERVER_IMAGE + 'items/' + item_pedido + '_' + item.variante + '_75x75.webp'
                        });
                    });
                    setImagenesData(arrayVariantesAux);
                }
            } catch (error) {
                console.log(error);
            }
        }

        const queryRecommendation = async () => {
            const cookies = new Cookies();
            const accessToken = cookies.get('accesToken');

            try {
                const url = process.env.REACT_APP_SERVER + `/api/v1/items/recommendation/${item_pedido}`;
                const response = await axios.get(url, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    }
                });
                if (response.data.values.length < 4) {

                    setSlidesToShow(response.data.values.length)
                } else {
                    setSlidesToShow(4)
                }
                setItemsRecommendation(response.data.values);
                setTitulo(response.data.titulo);

            } catch (error) {
                console.log(`Ocurrió error en la consulta de los items recomendatos: ${error}`);
            }
        }

        const queryDisponibilidad = async () => {
            const cookies = new Cookies();
            const accessToken = cookies.get('accesToken');

            const urlAgotado = process.env.REACT_APP_SERVER + `/api/v1/productExhausted/${item_pedido}`;
            const dataAgotado = await axios.get(urlAgotado, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });

            if (dataAgotado.data.length > 0) {
                let stock = dataAgotado.data[0].stock;
                valorStock = stock;
                let um = dataAgotado.data[0].um_inventario;
                setStock(stock);
                setUmStock(um);
            }
        }

        const queryPrecio = async () => {
            try {
                setDataPrecioAntes(0);
                setDataPrecio(0);
                setDataDescuento(0);
                const cookies = new Cookies();
                const accessToken = cookies.get('accesToken');
                if (accessToken === undefined) {
                    window.location.reload(true);
                }

                let precio = 0;
                let precioFinal = 0;
                let descuentoFinal = 0;
                const urlCliente = process.env.REACT_APP_SERVER + '/api/v1/validarListaPrecio';
                const respCliente = await axios.get(urlCliente, {
                    headers: {
                        "Authorization": `Bearer ${accessToken}`
                    }
                });

                const urlPrecios = process.env.REACT_APP_SERVER + '/api/v1/discounts/prices';
                const bodyPrecios = { item: item_pedido }
                const { data } = await axios.post(urlPrecios, bodyPrecios, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    }
                });

                setTipoClientePagina(respCliente.data.tipo_cliente_pagina);

                if (respCliente.data.lista_de_precios === '014') {

                    precioFinal = parseInt(data[0].precio_despues);
                    descuentoFinal = parseFloat(data[0].descuento);
                    precio = parseInt(data[0].precio_antes);

                    if (precio !== precioFinal) {
                        setDataPrecioAntes(precio)
                    }

                } else {

                    if (respCliente.data.lista_de_precios !== '001' && respCliente.data.lista_de_precios !== '010' && respCliente.data.lista_de_precios !== '014') {
                        precioFinal = parseInt(data[0].precio_despues);
                        descuentoFinal = parseFloat(data[0].descuento);
                        precio = parseInt(data[0].precio_antes);

                        if (precio !== precioFinal) {
                            setDataPrecioAntes(precio)
                        }

                    } else {

                        const urlDescuetnos = process.env.REACT_APP_SERVER + '/api/v1/discounts';
                        const bodyDescuentos = { item_pedido: item_pedido }
                        const responseDescuentos = await axios.post(urlDescuetnos, bodyDescuentos, {
                            headers: {
                                'Authorization': `Bearer ${accessToken}`
                            }
                        });

                        const urlDescuentosPersonalize = process.env.REACT_APP_SERVER + '/api/v1/discountsVolumePersonalize';
                        const bodyDescuentosPersonalize = { item: item_pedido, cantidad: objItem.cantidad, descuento: responseDescuentos.data[0].descuentoFinal * 100 };
                        const responseeDescuentoPersonalize = await axios.post(urlDescuentosPersonalize, bodyDescuentosPersonalize, {
                            headers: {
                                'Authorization': `Bearer ${accessToken}`
                            }
                        });

                        const urlDescuentosVolumen = process.env.REACT_APP_SERVER + '/api/v1/discountsVolume';
                        const bodyDescuentosVolumen = { item: item_pedido, cantidad: objItem.cantidad, descuento: responseeDescuentoPersonalize.data.descuentoFinal };
                        const responseeDescuentoVolumen = await axios.post(urlDescuentosVolumen, bodyDescuentosVolumen, {
                            headers: {
                                'Authorization': `Bearer ${accessToken}`
                            }
                        });
                        precio = data[0].precio;
                        precioFinal = parseInt(data[0].precio * (1 - parseFloat(responseeDescuentoVolumen.data.descuentoFinal) / 100))
                        descuentoFinal = parseFloat(responseeDescuentoVolumen.data.descuentoFinal).toFixed(2);

                        if (precio !== precioFinal) {
                            setDataPrecioAntes(precio)
                        }
                    }
                }

                setDataPrecio(Math.round(precioFinal));
                setDataDescuento(descuentoFinal);

                //Se pone 1 porque esta es la cantidad por defecto
                let total = precioFinal * 1
                if (total !== 0) {
                    if (valorStock !== 0) {
                        setDisabledButton(false);
                    }
                }
                const newObjItem = {
                    ...objItem,
                    cantidad: 1,
                    item: item_pedido,
                    precio: Math.round(precio),
                    descuento_original: descuentoFinal,
                    descuento: descuentoFinal,
                    valor_final: total,
                };
                setObjItem(newObjItem)
            } catch (err) {
                console.log(err);
            }
        }

        const queryDescuentoVolumenShow = async () => {
            const cookies = new Cookies();
            const accessToken = cookies.get('accesToken')
            try {
                const url = process.env.REACT_APP_SERVER + '/api/v1/discountsVolumeShow';
                const body = { item: item_pedido };
                const { data } = await axios.post(url, body, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    }
                });
                if(data.result){
                    setVolumenShow(data.descuentos);
                }else{
                    console.log('Error en la consulta de mostrar descuentos por volumen: ' + data.mensaje);
                }
            } catch (error) {
                console.log('Error en la consulta de mostrar descuentos por volumen: ' + error.message);
            }
        }

        const queryDescuentoPersonalizeShow = async () => {
            const cookies = new Cookies();
            const accessToken = cookies.get('accesToken')

            try {
                const url = process.env.REACT_APP_SERVER + '/api/v1/discountsPersonalizeShow';
                const body = { item: item_pedido };
                const { data } = await axios.post(url, body, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    }
                });
                if(data.result){
                    if (data.descuentos.length > 0) {
                        setDiscountShow({
                            descuento: data.descuentos[0].descuento + '%',
                            permiso: 'block'
                        })
                    } else {
                        setDiscountShow({
                            descuento: '0%',
                            permiso: 'none'
                        })
                    }
                }else{
                    setDiscountShow({
                        descuento: '0%',
                        permiso: 'none'
                    })
                }
            } catch (error) {
                console.log('Error en la consulta de mostrar descuentos por volumen: ' + error.message);
            }
        }

        const bannerIncicio = async () => {
            const cookies = new Cookies();
            const accessToken = cookies.get('accesToken');

            const urlBanner = process.env.REACT_APP_SERVER + '/api/v1/products/banner';
            const dataBanner = await axios.post(urlBanner, { pagina: 'productSingle' }, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });
            const banner = dataBanner.data;
            setImagenBanner(banner)

            const dataBanner3 = await axios.post(urlBanner, { pagina: 'productSingle3' }, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });
            const banner3 = dataBanner3.data;
            setImagenBanner3(banner3);

        }

        setTimeout(() => {
            bannerIncicio();
            queryPrecio();
            //queryUnidadMedida();
            queryDescuentoPersonalizeShow();
            queryDescuentoVolumenShow();
            queryDisponibilidad();
            queryRecommendation();
            queryUsos();
            queryAnexos();
            queryFichaTecnica();
            queryImagenes();
        }, 1000);
        // eslint-disable-next-line
    }, [item_pedido, Location]);


    return (
        <>
            <Header />
            {
                usosData ? 
            <Container>  
                {
                plantilla === false ?
                  <>
                    <div className="d-flex justify-content-center" style={{height: '35vh', alignItems: 'center' }}>
                        <Spinner animation="border" variant="warning" style={{ width: '100px', height: '100px' }} />
                    </div>
                  </>  
                :
                plantilla === 'B2C1' ?
                <PlantillaB2C1 imagenesData={imagenesData}
                    dataPrecio={dataPrecio}
                    dataPrecioAntes={dataPrecioAntes}
                    dataDescuento={dataDescuento}
                    dataUnidadMedida={dataUnidadMedida}
                    usosData={usosData}
                    descripcionUsos={descripcionUsos}
                    tipoClientePagina={tipoClientePagina}
                    cantidad={cantidad}
                    stock={stock}
                    umStock={umStock}
                    itemMetros={itemMetros}
                    handleCantidad={handleCantidad}
                    handleUnidadMedida={handleUnidadMedida}
                    queryAgregarCarrito={queryAgregarCarrito}
                    abrirModalDisponibilidad={abrirModalDisponibilidad}
                    modal={modal}
                    titulo={titulo}
                    itemsRecommendation={itemsRecommendation}
                    imagenBanner={imagenBanner}
                    imagenBanner3={imagenBanner3}
                    disabledButton={disabledButton}
                />
            :
                <>
                <Row xs={1} sm={2}>
                    <Col>
                        <Stack>
                            <div>
                                <Figure>
                                    <Figure.Image
                                        width={500}
                                        height={800}
                                        alt="imagenPrudct"
                                        src={imagenProductVariant}
                                        onError={(e) => e.target.src = process.env.REACT_APP_SERVER_IMAGE + 'items/0_0_550x550.webp'}
                                    />
                                </Figure>
                            </div>
                            <div>
                                {
                                    imagenesData.map((item, i) =>
                                        <Figure key={i}>
                                            <Figure.Image
                                                width={75}
                                                height={75}
                                                alt="variant"
                                                onClick={() => { setImagenProductVariant(item.variante)}}
                                                src={item.thumbnail}
                                            />
                                        </Figure>
                                    )
                                }
                            </div>
                        </Stack>
                    </Col>
                    <Col>
                        <h5>{usosData.descripcion_alternativa}</h5>
                        <Form.Select onChange={handleUnidadMedida} size="sm">
                            {
                                dataUnidadMedida.map((item, i) => 
                                    <Fragment key={i}>
                                        <option value={item.unidad_de_medida}>{item.unidad_de_medida}</option>
                                    </Fragment>
                                )
                            }
                        </Form.Select>
                        <p>{dataDescuento === 0.00 ? null : formatoPorcentaje(dataDescuento)}</p>

                        {
                            dataPrecioAntes === 0
                                ?
                                <div>
                                    <div className='div_precio'>
                                        <small><b>Precio: </b></small>
                                        <label className='label_precio'><p className="text_precio"><b>{formatoPesosColombianos.format(dataPrecio)}</b></p></label>
                                        <small>IVA incluido</small>
                                    </div>
                                    <div className='div_precio_metros'>
                                        {
                                            itemMetros
                                                ?
                                                <label ><p className="text_precio">{formatoPesosColombianos.format(dataPrecio / usosData.metros)} METRO CUADRADO</p></label>
                                                :
                                                <label></label>
                                        }
                                    </div>
                                </div>
                                :
                                <div>

                                    <div className='div_precio textoTachado'>

                                        <small><b>Precio Antes: </b></small>
                                        <label><b>{formatoPesosColombianos.format(dataPrecioAntes)}</b></label>
                                    </div>

                                    <div className='div_precio'>
                                        <small><b>Precio: </b></small>
                                        <label className='label_precio'><p className="text_precio"><b>{formatoPesosColombianos.format(dataPrecio)}</b></p></label>
                                        <small>IVA incluido   </small>
                                    </div>
                                    <div className='div_precio_metros'>
                                        {
                                            itemMetros
                                                ?
                                                <label ><p className="text_precio">{formatoPesosColombianos.format(dataPrecio / usosData.metros)} METRO CUADRADO</p></label>
                                                :
                                                <label></label>
                                        }
                                    </div>
                                </div>
                        }
                        
                        {
                            (usosData.precio_solo_pagina === tipoClientePagina)||(usosData.precio_solo_pagina === 'TODOS') 
                                ?
                                <Col xs={12} style={{color:"green"}} className={'divExclusivo'}>
                                    <Icon.Globe/><strong> Precio solo página</strong>
                                </Col>
                                
                                :
                                <Col xs={12} className={'divExclusivo'}>
                                    <small></small>
                                </Col>
                        } 

                        {
                            itemEspecial === '0'
                                ?
                                <InputGroup className="mb-3">
                                    <Button className='btnAdicionar' variant="outline-warning" id="button-addon1" disabled={disabledButton} onClick={() => queryAgregarCarrito()}>
                                        <Icon.CartCheckFill /><strong>Agregar al carrito</strong>
                                    </Button>
                                    {modal}
                                    <Form.Control
                                        type="number"
                                        aria-label="Example text with button addon"
                                        aria-describedby="basic-addon1"
                                        min="1"
                                        value={cantidad}
                                        onChange={handleCantidad}
                                    />
                                </InputGroup>
                                :
                                <Button variant="outline-warning" id="button-addon1" disabled={disabledButton} className='btnAdicionar' style={{ width: '100%' }} target={"_new"} href={`https://wa.me/${usosData.numero_whatsApp}?text=Hola, quisiera conocer más información sobre el producto ${usosData.descripcion_alternativa}, ítem ${usosData.item} con referencia ${usosData.referencia}`} >
                                    <Icon.Whatsapp />   Asesor
                                </Button>

                        }
                        <div className='div_caracteristicas'>
                            <p><b>Referencia: </b>{usosData.referencia}</p>
                            <hr />
                            <p><b>Codigo de Erp: </b>{usosData.item}</p>
                            <hr />
                            <p><b>Categoría: </b>{usosData.sublinea}</p>
                            <hr />
                            <p><b>Marca:</b> {usosData.marca}</p>
                            <hr />

                            {
                                stock === 0
                                    ?
                                    <p><b>Disponibilidad: <span style={{ color: 'red' }}>VALIDAR DISPONIBILIDAD</span></b></p>
                                    :
                                    <p><b>Disponibilidad:</b> {stock} {umStock} <Button variant="outline-secondary" size="sm" onClick={abrirModalDisponibilidad}><Icon.InfoCircleFill/></Button></p>
                            }

                            <hr />
                            <div style={{ display: discountShow.permiso }}>
                                <p>
                                    <b>Descuento Personalizado:  </b> {discountShow.descuento}
                                </p>
                                <hr />
                            </div>
                        </div>
                        <div>
                            <Slider {...settingsDescuentosVolumen}>
                                {
                                    volumenShow.map((item, i) =>
                                        <Table className="mx-auto" key={i}>
                                            <tbody>
                                                <tr>
                                                    <td><label><b>Cant.</b></label> {item.cantidad_minima + ' - ' + item.cantidad_maxima}</td>
                                                    <td><label><b>Desc.</b></label> {formatoPorcentaje(item.descuento)}</td>
                                                    <td><label><b>Tipo</b></label> {item.acumulativo_exclusivo}</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    )
                                }
                            </Slider>
                        </div>
                        {
                            imagenBanner.map((fila, id) =>
                                <div className={'banner'} key={id}>
                                    <a href={fila.telefono} target={'_new'}>
                                        <img src={imagenPublicidad + fila.pagina} alt="bannerPrincipal" style={{maxHeight:'max-content'}} id='Banner' />
                                    </a>
                                </div>
                            )
                        }

                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Container>
                            <hr />
                            <h4 className='title'>Información Adicional</h4>
                            <div style={{ textAlign: 'justify' }}>
                                {
                                    descripcionUsos[0]  ? 
                                    ( 
                                        descripcionUsos.map((html, index) => (
                                            <div key={index} dangerouslySetInnerHTML={{ __html: html }} />
                                        ))
                                    )
                                    :
                                        (usosData.descripcion_producto)
                                }
                            </div>
                            <hr />
                            <h4 className='title'>Ficha Técnica</h4>
                            {
                                fichaTecnicaData.map((item, i) =>
                                    <ListGroup className='archivo' defaultActiveKey="#link1" key={i}>
                                        <ListGroup.Item className='archivo' action onClick={() => window.open(process.env.REACT_APP_SERVER_IMAGE + 'pdf_ficha_tecnica/' + item.pdf_ficha_tecnica, '_blank')}>
                                            <Icon.FileEarmarkPdfFill />Ver Ficha
                                        </ListGroup.Item>
                                    </ListGroup>
                                )
                            }
                            <hr />
                            <h4 className='title'>Anexos</h4>
                            {
                                anexosData.map((item, i) =>
                                    <ListGroup defaultActiveKey="#link1" key={i}>
                                        <ListGroup.Item className='archivo' action onClick={() => window.open(process.env.REACT_APP_SERVER_IMAGE + 'pdf_anexos/' + item.pdf_anexos, '_blank')}>
                                            <Icon.FileEarmarkPdfFill />Ver Anexo
                                        </ListGroup.Item>
                                    </ListGroup>
                                )
                            }
                            <hr />
                        </Container>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Container>
                            <h3>{titulo}</h3>
                            <Slider {...settings}>
                                {
                                    itemsRecommendation.map((item, i) =>
                                        <Fragment key={i}>
                                            <CardProductoPrice propiedades={item} />
                                        </Fragment>
                                    )
                                }
                            </Slider>
                        </Container>
                        <br />
                    </Col>
                </Row>
                <a className='btn-wsp' target={"_new"} href={`https://wa.me/${usosData.numero_whatsApp}?text=Hola, me encuentro en la página web de la FERRETERIA MASTER y quisiera conocer más información sobre el producto ${usosData.descripcion_alternativa}, ítem ${usosData.item} con referencia ${usosData.referencia}`}  aria-label="Número de whatsApp de nuestros asesores">
                    <Card.Img className='btnWsp1' alt="75x75" src={process.env.REACT_APP_SERVER_IMAGE + 'estaticas/whatsapp1.webp'}/>
                </a>
                </>  
                }
            </Container>
            : 
            <Container><h3>Lo sentimos, este producto ya no está disponible</h3>
                <Row style={{alignItems: 'center'}}>
                    <Col sm={7}>
                        <Row className="m-3 p-3 mb-0">
                            <Col sm={12}>
                                <h3>Contáctenos</h3>
                            </Col>
                            <Col sm={12}>
                                <Card>
                                    <Card.Header>Sede Buga</Card.Header>
                                    <Card.Body>
                                        <blockquote className="blockquote mb-0">
                                            <small>
                                                <Icon.GeoAlt/>
                                                Dirección: CALLE 7 10 50
                                                <br></br>
                                                <Icon.Telephone/>
                                                Teléfono: 2363000
                                            </small>
                                        </blockquote>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                        <Row className="m-3 p-3 mb-0">
                            <Col sm={12}>
                                <Card>
                                    <Card.Header>Sede Palmira</Card.Header>
                                    <Card.Body>
                                        <blockquote className="blockquote mb-0">
                                            <small>
                                                <Icon.GeoAlt/>
                                                Dirección: CARRERA 33A 28 24
                                                <br></br>
                                                <Icon.Telephone/>
                                                Teléfono: 2660066
                                            </small>
                                        </blockquote>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                        <Row className="m-3 p-3 mb-0">
                            <Col sm={12}>
                                <Card>
                                    <Card.Header>Sede Tuluá</Card.Header>
                                    <Card.Body>
                                        <blockquote className="blockquote mb-0">
                                            <small>
                                                <Icon.GeoAlt/>
                                                Dirección: CARRERA 40 33 21
                                                <br></br>
                                                <Icon.Telephone/>
                                                Teléfono:2339733 
                                            </small>
                                        </blockquote>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={5} className="m-auto m-3 p-3 ">
                        <div style={{textAlign: 'center'}}>
                            <img src={imagenBanner2} style={{width:"100%", height:"100%"}} alt="product" className="img-fluid" />
                        </div>
                    </Col>
                </Row>
            </Container>
            }   
            <Footer />

            <Modal
            show={showModalDisponibilidad}
            keyboard={false}
            onHide={cerrarModalDisponibilidad}
        >
            <Modal.Header closeButton>
                <Modal.Title>Información</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    item.length > 0
                        ?
                        <Container>
                            <b>Item:</b> {item[0].descripcion_item}
                            <br></br>
                            <b>Código:</b> {item[0].item}
                            <br></br>
                            <b>UM:</b> {item[0].um_inventario}
                            <br></br>
                            <Row>
                                <Col sm={12}>
                                    <Table size="sm">
                                        <thead>
                                            <tr>
                                                <th>Instalación</th>
                                                <th>Disponible</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                item.map((item, i) =>
                                                    <tr key={i}>
                                                        <td>{arrayCentro[item.co]}</td>
                                                        <td>{item.disponibilidad}</td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </Container>
                        :
                        <Alert style={{ width: '400px' }} className="mx-auto text-center">
                            <b>Sin Resultados</b>
                        </Alert>
                }
            </Modal.Body>
        </Modal>
        </>

    )
}

export { ProductSingle };