import React, { useState, useEffect } from 'react';
import '../sources/styles/stylesCatalogo.css';
import axios from 'axios';
import Cookies from 'universal-cookie';
import { useLocation,  useParams} from 'react-router-dom';
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';
import { CardProductoPrice } from '../components/CardProductoPrice';
import { Col, Container, Row, Form, Pagination, Accordion, Button, Offcanvas, Card } from 'react-bootstrap';
import Slider from "react-slick";
import * as Icon from 'react-bootstrap-icons';

function Catalogo() {

    const Location = useLocation();
    const [dataSublinea, setDataSublinea] = useState([]);
    const [dataMarca, setDataMarca] = useState([]);
    const [singleLinea, setSingleLinea] = useState(undefined);
    const [lineaAux, setLineaAux] = useState(undefined);
    const [singleSublinea, setSingleSublinea] = useState(undefined);
    const [titleCategoria, setTitleCategoria] = useState('');
    const [titleBusqueda, setTitleBusqueda] = useState('');
    const [singleMarca, setSingleMarca] = useState(undefined);
    const [rangoInicial] = useState(0);
    const [rangoFinal, setRangoFinal] = useState(12);
    const [numPags, setNumPags] = useState([]);
    const [limitPage, setLimitPage] = useState(0);
    const [text, setText] = useState('');
    const [validacionPaginacion, setValidacionPaginacion] = useState(1);
    const [textValidacion, setTextValidacion] = useState(false);
    const [items, setItems] = useState([]);
    const [imagenBanner, setImagenBanner] = useState([]);
    const [imagenWhatsapp, setimagenWhatsapp] = useState([]);
    const [filtro, setFiltro] = useState(undefined);
    const [visualizacionFiltro, setVisualizacionFiltro] = useState('none');
    const [visualizacionProductos, setVisualizacionProductos] = useState('none');
    const [show, setShow] = useState(false);
    const cookie = new Cookies();
    const cookies = new Cookies();
    const accessToken = cookie.get('accesToken');
    const params = useParams();
    let urlMarca = params.marca;
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const imagenPublicidad = process.env.REACT_APP_SERVER_IMAGE + 'estaticas/';

    const queryTokenNuevo = async (item, tipo_cliente) => {
        const cookies = new Cookies();
        try {
            const ip = await consultarIp();
            const user = {id_cliente:ip,nombre:"",apellido_1:"",apellido_2:"",tipo_usuario:"temporal"};
            cookies.set('user', user, { path: '/' });
            const url = process.env.REACT_APP_SERVER + '/api/v1/token/productSingle';
            const body = { item_pedido: item, tipo_cliente: tipo_cliente, ipUsuario: ip };
            const { data } = await axios.post(url, body);
            cookies.set('accesToken', data.accessToken, { path: '/' });
            return data.accessToken;
        } catch (error) {
            console.log('Error en la consulta de token productSingle: ' + error.message);
            return null;
        }
    }

    const consultarIp = async () => {
        let ip = ''
        try {
            const url = 'https://www.cloudflare.com/cdn-cgi/trace';
            const { data } = await axios.get(url);
            ip = data.split('\n')
            ip = ip[2].split('=')
            ip = ip[1];
        } catch (err) {
            const url2 = 'https://geolocation-db.com/json/';
            const { data } = await axios.get(url2);
            ip = data.IPv4;
        }
        return ip
    }
    const queryLineas = async () => {
        const cookies = new Cookies();
        const accessToken = cookies.get('accesToken')
        try {
            const urlLinea = process.env.REACT_APP_SERVER + '/api/v1/items/linea';
            const responseLinea = await axios.get(urlLinea, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });
            cookies.set('accesToken', responseLinea.data[1], { path: '/' });
        } catch (err) {
            console.log('Error en la consulta de lineas: ' + err)
        }
    }
    const querySublinea = async (cod_linea, singleLinea2) => {
        const cookies = new Cookies();
        if (lineaAux !== cod_linea) {
            setLineaAux(cod_linea);

            const url = process.env.REACT_APP_SERVER + `/api/v1/items/sublinea/${cod_linea}`;
            const { data } = await axios.get(url, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });
            setSingleLinea(cod_linea)
            setDataSublinea(data[0]);
            if(data[0].length > 0) { 
                cookies.set('accesToken', data[1], { path: '/' })
            }
        }
        setTextValidacion(false)
        console.log(textValidacion);
        setText('');
    }
    const queryMarca = async () => {
        const cookies = new Cookies();
        const accessToken = cookies.get('accesToken')
        const menu = cookies.get('menu')

        let datos = []
        if (menu !== undefined) {
            datos = [menu.split('_')[1], menu.split('_')[0]];
        }
        try {
            const urlLinea = process.env.REACT_APP_SERVER + '/api/v1/items/marca';
            const responseLinea = await axios.post(urlLinea, datos, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });

            setSingleLinea([])
            setDataMarca(responseLinea.data[0]);
            cookies.set('accesToken', responseLinea.data[1], { path: '/' });
        } catch (err) {
            console.log('Error en la consulta de lineas: ' + err)
        }
    }
    const handleFiltro = async (e) => {
        setFiltro(e.target.value);
        cookies.set('filtro', e.target.value);
        if (text === '') {

            if (singleLinea === undefined && singleSublinea === undefined) {
                if (singleMarca !== undefined) {
                    await queryNumsPags2(singleLinea, singleSublinea, singleMarca, rangoFinal);
                    const url = process.env.REACT_APP_SERVER + `/api/v1/items/marcaItems`;
                    const body = { cod_marca: singleMarca, rangoInicial, rangoFinal: rangoFinal, filtro: e.target.value }
                    const { data } = await axios.post(url, body, {
                        headers: {
                            'Authorization': `Bearer ${accessToken}`
                        }
                    });
                    setItems(data)
                } else {
                    await queryNumsPags2(singleLinea, singleSublinea, singleMarca, rangoFinal);
                    const url = process.env.REACT_APP_SERVER + '/api/v1/items/catalogo';
                    const body = { cod_linea: singleLinea, cod_sublinea: singleSublinea, cod_marca: singleMarca, rangoInicial, rangoFinal: rangoFinal, filtro: e.target.value }
                    const { data } = await axios.post(url, body, {
                        headers: {
                            'Authorization': `Bearer ${accessToken}`
                        }
                    });
                    setItems(data);
                }

            } else if (singleLinea !== undefined && singleSublinea === undefined) {
                await queryNumsPags2(singleLinea, singleSublinea, singleMarca, rangoFinal);
                const url = process.env.REACT_APP_SERVER + '/api/v1/items/catalogo';
                const body = { cod_linea: singleLinea, cod_sublinea: singleSublinea, cod_marca: singleMarca, rangoInicial, rangoFinal: rangoFinal, filtro: e.target.value }
                const { data } = await axios.post(url, body, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    }
                });
                setItems(data);
            } else if (singleLinea === undefined && singleSublinea !== undefined) {
                await queryNumsPags2(singleLinea, singleSublinea, singleMarca, rangoFinal);
                const url = process.env.REACT_APP_SERVER + `/api/v1/items/sublineaItems`;
                const body = { cod_linea: singleLinea, cod_sublinea: singleSublinea, cod_marca: singleMarca, rangoInicial, rangoFinal: rangoFinal, filtro: e.target.value }
                const { data } = await axios.post(url, body, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    }
                });
                setItems(data)
            } else if (singleLinea !== undefined && singleSublinea !== undefined) {
                await queryNumsPags2(singleLinea, singleSublinea, singleMarca, rangoFinal);
                const url = process.env.REACT_APP_SERVER + `/api/v1/items/sublineaItems`;
                const body = { cod_linea: singleLinea, cod_sublinea: singleSublinea, cod_marca: singleMarca, rangoInicial, rangoFinal: rangoFinal, filtro: e.target.value }
                const { data } = await axios.post(url, body, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    }
                });
                setItems(data)
            }
        } else {
            const url = process.env.REACT_APP_SERVER + '/api/v1/items/busqueda';
            const body = { text: text, rangoInicial: rangoInicial, rangoFinal: rangoFinal, filtro: e.target.value }
            const { data } = await axios.post(url, body, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });
            setItems(data);
        }
    }

    const handleRangoFinal = async (e) => {
        let rango = e.target.value;
        let inicialRango = 0;
        let index =  0;
        setRangoFinal(rango);
        cookies.set('rango', rango);
        if (cookies.get('paginas') !== undefined) {
            index = parseInt(cookies.get('paginas'));

            inicialRango = rangoFinal * (index - 1);
        }

        if (text === '') {
            if (singleLinea === undefined && singleSublinea === undefined) {
                if (singleMarca !== undefined) {
                    await queryNumsPags2(singleLinea, singleSublinea, singleMarca, rango);
                    const url = process.env.REACT_APP_SERVER + `/api/v1/items/marcaItems`;
                    const body = { cod_marca: singleMarca, rangoInicial: inicialRango, rangoFinal: rango, filtro: filtro }
                    const { data } = await axios.post(url, body, {
                        headers: {
                            'Authorization': `Bearer ${accessToken}`
                        }
                    });
                    setItems(data)
                } else {
                    await queryNumsPags2(singleLinea, singleSublinea, singleMarca, rango);
                    const url = process.env.REACT_APP_SERVER + '/api/v1/items/catalogo';
                    const body = { cod_linea: singleLinea, cod_sublinea: singleSublinea, rangoInicial: inicialRango, rangoFinal: rango, filtro: filtro }
                    const { data } = await axios.post(url, body, {
                        headers: {
                            'Authorization': `Bearer ${accessToken}`
                        }
                    });
                    setItems(data);
                }
            } else if (singleLinea !== undefined && singleSublinea === undefined) {
                await queryNumsPags2(singleLinea, singleSublinea, singleMarca, rango);
                const url = process.env.REACT_APP_SERVER + '/api/v1/items/catalogo';
                const body = { cod_linea: singleLinea, cod_sublinea: singleSublinea, rangoInicial: inicialRango, rangoFinal: rango, filtro: filtro }
                const { data } = await axios.post(url, body, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    }
                });
                setItems(data);
            } else if (singleLinea === undefined && singleSublinea !== undefined) {
                await queryNumsPags2(singleLinea, singleSublinea, singleMarca, rango);
                const url = process.env.REACT_APP_SERVER + `/api/v1/items/sublineaItems`;
                const body = { cod_linea: singleLinea, cod_sublinea: singleSublinea, rangoInicial: inicialRango, rangoFinal: rango, filtro: filtro }
                const { data } = await axios.post(url, body, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    }
                });
                setItems(data)
            } else if (singleLinea !== undefined && singleSublinea !== undefined) {
                await queryNumsPags2(singleLinea, singleSublinea, singleMarca, rango);
                const url = process.env.REACT_APP_SERVER + `/api/v1/items/sublineaItems`;
                const body = { cod_linea: singleLinea, cod_sublinea: singleSublinea, rangoInicial: inicialRango, rangoFinal: rango, filtro: filtro }
                const { data } = await axios.post(url, body, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    }
                });
                setItems(data)
            }
        } else {
            const url = process.env.REACT_APP_SERVER + '/api/v1/items/busqueda';
            const body = { text: text, rangoInicial: rangoInicial, rangoFinal: rango, filtro: filtro }
            console.log(body)
            const { data } = await axios.post(url, body, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });
            setItems(data);
        }
       setTimeout(() => {
            let paginaActiva = document.getElementsByClassName('page-item');            
                    
            for (let i = 0; i < paginaActiva.length-1; i++) {  
                
                if(paginaActiva[i] === paginaActiva[index -1]){
                    paginaActiva[i].className = 'page-item active';                
                }else{
                    paginaActiva[i].className = 'page-item';
                }                
            }
       }, 2000);
        
    }
    const handlePagination = async (index, linea, sublinea, marca ) => {
        setValidacionPaginacion(index);
        let filtroInicial = await ValidarFiltroInicial();
        setFiltro(filtroInicial)
        
        let rangoF = rangoFinal;
        if(cookies.get("rango") !== undefined){
            rangoF = parseInt(cookies.get("rango"));
            setRangoFinal(rangoF);
        }

        window.scroll(0, 0)

        let inicialRango = 0;

        inicialRango = rangoFinal * (index - 1);
        if (text === '' && cookies.get('textoBuscar') === undefined) {

            if (linea !== undefined || sublinea !== undefined) {
                const url = process.env.REACT_APP_SERVER + '/api/v1/items/catalogo';
                const body = { cod_linea: linea, cod_sublinea: sublinea, rangoInicial: inicialRango, rangoFinal: rangoF, filtro: filtroInicial }
                const { data } = await axios.post(url, body, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    }
                });
                setItems(data);

            } else {
                const url = process.env.REACT_APP_SERVER + '/api/v1/items/marcaItems';
                const body = { cod_marca: marca, cod_sublinea: marca, rangoInicial: inicialRango, rangoFinal: rangoF, filtro: filtroInicial }
                const { data } = await axios.post(url, body, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    }
                });
                setItems(data);
            }
        } else {

            const url = process.env.REACT_APP_SERVER + '/api/v1/items/busqueda';
            const body = { text: cookies.get('textoBuscar'), rangoInicial: inicialRango, rangoFinal: rangoF, filtro: filtroInicial };
            const { data } = await axios.post(url, body, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });
            
            setItems(data);
        }
        setTimeout(() => {
            let paginaActiva = document.getElementsByClassName('page-item');            
            for (let i = 0; i < paginaActiva.length-1; i++) {                
                if(paginaActiva[i] === paginaActiva[index-1]){
                    paginaActiva[i].className = 'page-item active';                
                }else{
                    paginaActiva[i].className = 'page-item';
                }                
            }
        }, 2000);
    }
    const groupPageNext = async (index) => {
        let auxArray = [];
        let diferencia = limitPage - index;
        if (diferencia > 5) {
            for (let i = index + 1; i < index + 6; i++) {
                auxArray.push(i);
            }
        } else {
            for (let i = index + 1; i < limitPage; i++) {
                auxArray.push(i);
            }
        }
        setNumPags(auxArray);
    }
    const groupPagePrev = async (index) => {
        let auxArray = [];
        let diferencia = (index + 1) % 5;
        if (diferencia > 0) {
            for (let i = index - (4 + diferencia); i < (index + 1) - diferencia; i++) {
                auxArray.push(i);
            }
        } else {
            for (let i = index - 9; i < index - 4; i++) {
                auxArray.push(i);
            }
        }
        setNumPags(auxArray);
    }
    const queryItemsLinea = async (cod_linea, linea, validacionOffCanvasLinea = false) => {
        let filtroInicial = await ValidarFiltroInicial();
        setFiltro(filtroInicial)
        if (validacionOffCanvasLinea) {
            setShow(false);
        }
        
        setText('');
        setTextValidacion(false)
        setSingleLinea(cod_linea);
        await queryNumsPags2(cod_linea, undefined, undefined, rangoFinal, false);
        setTitleBusqueda('');
        setSingleSublinea(undefined);
        setSingleMarca(undefined);  
        
        if (cookies.get('paginas') !== undefined && cookies.get('paginas') !== 1) {
            setValidacionPaginacion(cookies.get('paginas'))
            
            handlePagination(cookies.get('paginas'),cod_linea, undefined, undefined)
        } else {
            const url = process.env.REACT_APP_SERVER + '/api/v1/items/catalogo';
            const body = { cod_linea, cod_sublinea: undefined, rangoInicial, rangoFinal, filtro: filtroInicial }
            const { data } = await axios.post(url, body, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });

            setValidacionPaginacion(1)
            setItems(data);
        }
              
        setTitleCategoria(linea);
       
    }
    const queryItemsSublinea = async (cod_sublinea, sublinea, validacionOffCanvasSublinea = false) => {
        let filtroInicial = await ValidarFiltroInicial();
        setFiltro(filtroInicial)
        if (validacionOffCanvasSublinea) {
            setShow(false);
        }
        setTextValidacion(false)
        setText('');
        setTitleBusqueda('')
        await queryNumsPags2(undefined, cod_sublinea, undefined, rangoFinal, false);

        if (cookies.get('paginas') !== undefined && cookies.get('paginas') !== 1) {
         
            setValidacionPaginacion(cookies.get('paginas'))            
            handlePagination(cookies.get('paginas'),undefined, cod_sublinea, undefined)
        } else {

            const url = process.env.REACT_APP_SERVER + `/api/v1/items/sublineaItems`;
            const body = { cod_linea: singleLinea, cod_sublinea: cod_sublinea, rangoInicial, rangoFinal, filtro: filtroInicial }
            const { data } = await axios.post(url, body, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });
            
            setValidacionPaginacion(1)
            setItems(data)
        }
        

        setSingleSublinea(cod_sublinea);
        setSingleMarca(undefined)
        setTitleCategoria(sublinea);
    }
    const queryItemsMarca = async (cod_marca, marca, validacionOffCanvasSublinea = false) => {
        setValidacionPaginacion(1)
        if(validacionOffCanvasSublinea){
            setShow(false);
        }
        setTextValidacion(false)
        setText('');
        setTitleBusqueda('')
        await queryNumsPags2(undefined, undefined, marca, rangoFinal, false);
        const url = process.env.REACT_APP_SERVER + `/api/v1/items/marcaItems`;
        const body = {cod_marca: cod_marca, cod_sublinea:marca, rangoInicial, rangoFinal, filtro: filtro}
        const { data } = await axios.post(url, body, {
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        });
        
        setItems(data)
        setSingleLinea(undefined);
        setSingleSublinea(undefined);
        setSingleMarca(marca);
        cookies.remove('textoBuscar');
        if(data.length === 0){
            setTitleCategoria('Lamentablemente, No pudimos encontrar ningún resultado para tu búsqueda.');
        }else{
            setTitleCategoria(marca);
        }
    }
    const queryNumsPags2 = async (cod_linea, cod_sublinea, cod_marca, finalRango, validacionText) => {
        if(text === ''){
            const url = process.env.REACT_APP_SERVER + `/api/v1/items/numPags`;
            const cuerpo = {rangoFinal: finalRango === undefined ? rangoFinal : finalRango, singleLinea: cod_linea, singleSublinea: cod_sublinea, singleMarca: cod_marca}
                const { data } = await axios.post(url, cuerpo, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    }
                });
                let arrayAux = [];
                if(data[0] >= 5){
                    setNumPags([0, 1, 2, 3, 4])
                }else{
                    for(let index = 0; index < data[0]; index++){
                        arrayAux.push(index)
                    }
                    
                    setNumPags(arrayAux)
                }
                setLimitPage(data[0])
        }else{
            if(!validacionText){
                const url = process.env.REACT_APP_SERVER + `/api/v1/items/numPags`;
                const cuerpo = { rangoFinal: finalRango === undefined ? rangoFinal : finalRango, singleLinea: cod_linea, singleSublinea: cod_sublinea, singleMarca: cod_marca }
                const { data } = await axios.post(url, cuerpo, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    }
                });
                let arrayAux = [];
                if (data[0] >= 5) {
                    setNumPags([0, 1, 2, 3, 4])
                } else {
                    for (let index = 0; index < data[0]; index++) {
                        arrayAux.push(index)
                    }

                    setNumPags(arrayAux)
                }
                setLimitPage(data[0])
            } else {
                const urlPags = process.env.REACT_APP_SERVER + '/api/v1/items/numPags/busqueda';
                const body = { text, rangoInicial: 0, rangoFinal: finalRango === undefined ? rangoFinal : finalRango }
                const responsePags = await axios.post(urlPags, body, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    }
                });

                let arrayAux = [];
                if (responsePags.data[0] > 5) {
                    arrayAux = [0, 1, 2, 3, 4]
                } else {
                    for (let index = 0; index < responsePags.data[0]; index++) {
                        arrayAux.push(index)
                    }
                }
                setNumPags(arrayAux)
                setLimitPage(responsePags.data[0])
            }
        }
    }
    const validationRoute = async () => {

        const cookies = new Cookies();
        let accessToken = cookies.get('accesToken');
        if (!accessToken) {
            if(urlMarca !== undefined){
                if(!accessToken){
                    accessToken = await queryTokenNuevo(urlMarca, 'B2C');
                    window.location.reload(true);
                }
            }
        }
        try {
            const url = process.env.REACT_APP_SERVER + "/requireAuth/routes";
            await axios.get(url, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });

        } catch (error) {
            console.log('La validación presentó error')
            console.log(error);
            cookies.remove('accessToken', { path: "/" });
            cookies.remove('username', { path: "/" });
            cookies.remove('id', { path: "/" });
            window.location.href = "/inicioSesion";
        }
    }
    const ValidarRango = async () =>{
        let finalRango = 12;

        if(cookies.get("rango") !== undefined){
            finalRango = cookies.get("rango"); 
        }
        return finalRango;
    }
    const ValidarFiltroInicial = async () =>{
        let filtroInicial = "";

        if (cookies.get('filtro') !== undefined) {
            filtroInicial= cookies.get('filtro');
        }
        return filtroInicial;
    }
    useEffect(() => {
        validationRoute();
        const cookies = new Cookies();
        const accessToken = cookies.get('accesToken');
        const user = cookies.get('user');
        const menu = cookies.get('menu');
        const pagina = cookies.get('paginas');

        if (pagina === undefined) {
            cookies.set('paginas', 1, { path: '/' });
        }

        document.getElementsByClassName('accordion-body').ariaExpanded = 'false';
        const queryItems = async () => {
            let inicioRagon = 0;
            if(cookies.get('rangoInicia') !== undefined){
                inicioRagon = cookies.get('rangoInicia');

            }else{
                inicioRagon = 0;
            }
            let finalRango = await ValidarRango();
            setRangoFinal(finalRango);
            
            let filtroInicial = await ValidarFiltroInicial();
            setFiltro(filtroInicial)

            const url = process.env.REACT_APP_SERVER + '/api/v1/items/catalogo';
            const body = { rangoInicial: inicioRagon, rangoFinal: finalRango, filtro: filtroInicial }
            const { data } = await axios.post(url, body, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });
            setItems(data);
        }

        const queryNumPags = async (singleLinea, singleSublinea) => {
            let finalRango = await ValidarRango();
            setRangoFinal(finalRango);
            
            const url = process.env.REACT_APP_SERVER + `/api/v1/items/numPags`;
            const cuerpo = { rangoFinal: finalRango, singleLinea, singleSublinea }
            const { data } = await axios.post(url, cuerpo, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });
            let arrayAux = [];
            if(data[0] >= 5){
                setNumPags([0, 1, 2, 3, 4])
            }else{
                for(let index = 0; index < data[0]; index++){
                    arrayAux.push(index)
                }
                setNumPags(arrayAux)
            }
            setLimitPage(data[0])
        }
        if (menu !== undefined) {
            
            if(urlMarca !== undefined){
                queryItemsMarca(undefined, urlMarca);
            }else{
                queryNumPags();
                let datosMenu = menu.split("_");
            
                if (datosMenu[0] === "sublinea") {
                    queryItemsSublinea(datosMenu[1], datosMenu[2]);
                } else {
                    if (datosMenu[0] === "linea") {   
                        queryItemsLinea(datosMenu[1], datosMenu[2]);
                    } else {

                        setVisualizacionProductos('block')
                        queryItemsLinea(datosMenu[1], datosMenu[2]);
                        querySublinea(datosMenu[1], '')
                    }
                }
            }

            if(pagina !== 1 && pagina !== undefined){

                setValidacionPaginacion(pagina)

            }
        } else {            

            if (Location.state !== null) {
                if (Location.state.page === '/' || Location.state.page === '/productSingle' || Location.state.page === '/carrito' || Location.state.page === '/estadoPedidos' || Location.state.page === '/estadoCartera' || Location.state.page === '/Ujueta' || Location.state.page === '/productSingleUjueta') {

                    if(urlMarca !== undefined){
                        setTextValidacion(false)
                        setText('');
                        setTitleBusqueda('')
                    }else{
                        setTitleBusqueda(Location.state.text)
                        setText(Location.state.text);
                        setTextValidacion(true);  
                    }
                    setItems(Location.state.data);
                    setNumPags(Location.state.numPags);
                    setLimitPage(Location.state.limitPags)                                 
                } else {
                    if(urlMarca !== undefined){ 
                        queryItemsMarca(undefined, urlMarca);
                    }else{
                        queryNumPags();
                    }                     
                }
            } else {
                if(urlMarca !== undefined){
                    queryItemsMarca(undefined, urlMarca);
                }else{
                    queryNumPags();
                }
            }
            
            if(pagina !== 1 && pagina !== undefined){
                handlePagination(pagina, undefined, undefined, undefined)
                setValidacionPaginacion(pagina)
            }else{

                if(cookies.get('textoBuscar') === undefined){
                    if(urlMarca !== undefined){
                        queryItemsMarca(undefined, urlMarca);
                    }else{
                        queryItems();
                    }
                }else{
                    setTitleBusqueda(cookies.get('textoBuscar'))
                    handlePagination(1, undefined, undefined, undefined);
                    setValidacionPaginacion(1)
                }
            }

        }

        if(user){
            if (user.tipo_usuario === 'temporal' || user.lista_de_precios === '014' || user.lista_de_precios === '001') {
                setVisualizacionFiltro('block')
            }
        }  

        const bannerIncicio = async () => {

            const urlBanner = process.env.REACT_APP_SERVER + '/api/v1/products/banner';
            const dataBanner = await axios.post(urlBanner, { pagina: 'catalogo' }, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });
            const banner = dataBanner.data;
            setImagenBanner(banner)

            const urlBannerW = process.env.REACT_APP_SERVER + '/api/v1/products/banner';
            const dataBannerW = await axios.post(urlBannerW, {pagina: 'whatsapp'}, {
                headers: {
                    'Authorization':`Bearer ${accessToken}`
                }
            });
            const BannerW = dataBannerW.data;
            setimagenWhatsapp(BannerW); 
        }
        
        bannerIncicio();

        //actualizarRango(cookies.get("textoBuscar"), rangoInicial, cookies.get("rango"), filtro);
        // eslint-disable-next-line 
    }, []);

    let settingsBanners = {
        autoplay: true,
        speed: 500,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <>
            <Header busquedaItems={{ setItems, setLimitPage, setNumPags, setTitleBusqueda, setText, rangoFinal, filtro, setValidacionPaginacion, setTextValidacion, setVisualizacionProductos }} />
            <Container>
                <Row>
                    <Col sm={3} xs={12} >
                        <Row>
                            <Col xs={12} className='flechas'>
                                <Slider {...settingsBanners} >
                                    {
                                        imagenBanner !== []
                                        ?
                                        
                                        imagenBanner.map((fila, id) =>
                                            <div key={id} >
                                                <a href={fila.telefono} target={'_new'} ><img loading='lazy' fallback={<p>Cargando</p>} src={imagenPublicidad + fila.pagina} alt="bannerPrincipal" className="img-fluid" style={{maxHeight:'max-content'}} id='imgBanner' /></a>
                                            </div>
                                        )
                                        :
                                        <label></label>
                                        
                                    }
                                </Slider>
                            </Col>
                        </Row>
                        <br></br>
                        <Row>
                            <Col xs={6} sm={7} md={12} lg={5}>
                                <Form.Select size="sm" onChange={handleRangoFinal} value={rangoFinal} className={'filtroSelect'}>
                                    <option value={12}>{12}</option>
                                    <option value={24}>{24}</option>
                                </Form.Select>
                            </Col>
                            <Col xs={6} sm={7} md={12} lg={7} style={{ display: visualizacionFiltro }}>
                                <Form.Select size="sm" onChange={handleFiltro} value={filtro} className={'filtroSelect'}>
                                    <option value={''}>Más vendidos</option>
                                    <option value={'Mayor'}>Mayor Precio</option>
                                    <option value={'Menor'}>Menor Precio</option>
                                </Form.Select>
                            </Col>
                        </Row>
                        <br></br>
                        <Col className={'divCanvas'}>
                        <div id="divOffcanvasCategorias">
                            <Button variant="outline-warning" onClick={handleShow} style={{ margin: '10px 0px', fontSize: '16px' }}>
                                <Icon.ListUl/> MARCAS DE PRODUCTOS 
                            </Button>
                            <Offcanvas show={show} onHide={handleClose}>
                                <Offcanvas.Header closeButton />
                                <Offcanvas.Body>
                                    <Accordion >
                                        <Accordion.Item eventKey="0" style={{ display: visualizacionProductos }} >
                                            <Accordion.Header className={'headerAcordion'}><h5 onClick={() => queryLineas()} className='h5_titulosMenu' style={{ width: '100%' }}>SUBLINEAS DE PRODUCTOS</h5></Accordion.Header>
                                            <Accordion.Body>
                                                <ul>
                                                    {
                                                        dataSublinea.map((element, e) =>
                                                            <li key={e} className="li_filtros"><p className="p_filtros" onClick={() => queryItemsSublinea(element.cod_criterio, element.descripcion_alternativa === null ? element.descripcion_criterio : element.descripcion_alternativa)}>{element.descripcion_alternativa === null ? element.descripcion_criterio.toUpperCase() : element.descripcion_alternativa.toUpperCase()}</p></li>

                                                        )
                                                    }
                                                </ul>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header className={'headerAcordion'}><h5 onClick={() => queryMarca()} className='h5_titulosMenu' style={{ width: '100%' }}>MARCAS DE PRODUCTOS</h5></Accordion.Header>
                                            <Accordion.Body>
                                                <Accordion>
                                                    <ul>
                                                        {
                                                            dataMarca.map((element, e) =>
                                                                <li key={e}><p className="p_filtros" onClick={() => queryItemsMarca(element.cod_criterio, element.descripcion_alternativa === null ? element.descripcion_criterio : element.descripcion_alternativa, true)}>{element.descripcion_alternativa === null ? element.descripcion_criterio : element.descripcion_alternativa}</p> </li>
                                                            )
                                                        }
                                                    </ul>

                                                </Accordion>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </Offcanvas.Body>
                            </Offcanvas>
                        </div>
                        <div id="divAccordionCategorias">
                            <Accordion>
                                <Accordion.Item style={{ display: visualizacionProductos }} >
                                    <Accordion.Header className={'headerAcordion'}><h5 className='h5_titulosMenu' style={{ height: '35px', width: '100%' }}>SUBLINEAS DE PRODUCTOS</h5></Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            {
                                                dataSublinea.map((element, e) =>
                                                    <li key={e} className="li_filtros"><p className="p_filtros" onClick={() => queryItemsSublinea(element.cod_criterio, element.descripcion_alternativa === null ? element.descripcion_criterio : element.descripcion_alternativa)}>{element.descripcion_alternativa === null ? element.descripcion_criterio.toUpperCase() : element.descripcion_alternativa.toUpperCase()}</p></li>
                                                )
                                            }
                                        </ul>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1" id='acordionMarcas'>
                                    <Accordion.Header className={'headerAcordion'}><h5 onClick={() => queryMarca()} className='h5_titulosMenu' style={{ height: '35px', width: '100%' }}>MARCAS DE PRODUCTOS</h5></Accordion.Header>
                                    <Accordion.Body>
                                        <Row>
                                            <Col sm={12} className="overflow-auto">
                                                <div className="divCategorias">
                                                    <Accordion>
                                                        {
                                                            dataMarca.map((item, i) =>
                                                                <Accordion.Item key={i} eventKey={i}>
                                                                    <p className="p_filtros" onClick={() => queryItemsMarca(item.cod_criterio, item.descripcion_alternativa === null ? item.descripcion_criterio : item.descripcion_alternativa)}>{item.descripcion_alternativa === null ? item.descripcion_criterio : item.descripcion_alternativa}</p>

                                                                </Accordion.Item>

                                                            )
                                                        }
                                                    </Accordion>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                        </Col>                   
                    </Col>
                    <Col sm={9} xs={12}>
                        <Row className='redes ps-5'>
                            <Col className="mt-4" sm={11} md={8} lg={6} style={{ display: visualizacionFiltro }}>
                                <iframe title="facebook" src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FFerreteriaMaster1&tabs&width=320&height=130&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=false&appId" width="320" height="130" style={{border:'none',overflow:'hidden'}}  scrolling="no" allowFullScreen={true} allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                            </Col>
                            <Col sm={11} md={4} lg={6} style={{ display: visualizacionFiltro }}>
                                <iframe id='instagram' title='instagram' src="https://www.instagram.com/ferreteria.master/embed" scrolling="no" width="100%" height="120px" allowtransparency="true"></iframe>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={9}>
                                <b className='b_textProducto'>{titleBusqueda === '' ? titleCategoria : 'Resultado de ' + titleBusqueda}</b>
                            </Col>
                        </Row>
                        <Row>
                            {
                                items.map((item, i) =>
                                    <Col key={i} className='imgCatalogo'>
                                        <CardProductoPrice propiedades={item} />
                                    </Col>
                                )
                            }
                        </Row>
                        <Row>
                            <Col className="mx-auto p-3" style={{ width: '300px' }}>
                                <Pagination className="mx-auto" style={{ width: '300px' }}>
                                    {numPags[0] + 1 === 1 ? <div style={{ width: "40px" }}></div> : <Pagination.Prev onClick={() => groupPagePrev(numPags[numPags.length - 1])} />}
                                    {
                                        numPags.map((item, i) =>
                                            <Pagination.Item key={i} onClick={() => { handlePagination(item + 1, singleLinea, singleSublinea ,singleMarca); cookies.set('paginas', item + 1, { path: '/' }) }} active={validacionPaginacion === item + 1}>{item + 1}</Pagination.Item>
                                        )
                                    }
                                    {numPags[numPags.length - 1] + 1 >= limitPage ? null : <Pagination.Next onClick={() => groupPageNext(numPags[numPags.length - 1])} />}

                                </Pagination>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
            {
                imagenWhatsapp.map((fila, id) =>
                    <a key={id} className='btn-wsp' target={"_new"} href={fila.telefono} aria-label="Número de whatsApp de nuestros asesores">
                        <Card.Img className='btnWsp1' alt="75x75" src={imagenPublicidad + fila.pagina}/>
                    </a>
                )
            } 
            <Footer />
        </>
    )
}

export { Catalogo };